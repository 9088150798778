import React from "react";
import { Link } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Badge,
    Table,
    Pagination, PaginationItem, PaginationLink,
} from "reactstrap";
import anime3 from '../assets/img/anime3.png';
import ApplicationSettings from "./ApplicationSettings/ApplicationSettings";
import HelpfulTips from "./HelpfulTips/HelpfulTips";
import HomeCategories from "./HomeCategories/HomeCategories";
import PromotionBanners from "./PromotionBanners/PromotionBanners";
import Questions from "./Questions/Questions";
import Faqs from "./Faqs/Faqs";

function AppContent () {
    return (
        <>
            <div className="content">
                <ApplicationSettings />
                <PromotionBanners />
                <HelpfulTips />
                <HomeCategories />
                <Row>
                    <Questions />
                </Row>
                <Row>
                    <Faqs />
                </Row>
            </div>
        </>
    )
}

export default AppContent   ;