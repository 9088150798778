import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    FormGroup,
    Label,
    Row, 
    Col,
    Button,
    Badge,
} from "reactstrap";
import { getQuestionsListAction, QuestionsDeleteAction } from "../../redux/actions/questionsAction";
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import EditIcon from "../../components/Icons/EditIcon";
import Pagination from "../../components/Pagination/Pagination";
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";

function Questions (props) {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const initialPage = parseInt(urlParams.get("page"), 10) ? parseInt(urlParams.get("page"), 10) : 1;
  const initialSearch = urlParams.get("searchBy") ?? ""
  const [currentPageNumber, setcurrentPageNumber] = useState(initialPage);
  const [searchBy, setSearchBy] = useState(initialSearch);

    useEffect(()=>{
      if(currentPageNumber) props.getQuestionsListAction(currentPageNumber,"",searchBy).then(response => {});
    },[currentPageNumber,searchBy]);

    const deleteClickHandler = (id) => {
      props.QuestionsDeleteAction(id).then(response => {
        if(response) props.getQuestionsListAction(currentPageNumber);
      });
    }
    return (
      <>
        <Col md="12">
          <Card className="card-plain tpf-card">
            <CardHeader>
              <Row>
                <Col md="4">
                  <CardTitle tag="h4">
                    Personlization Questions
                    <Badge color="info" pill>{props.questions_pagination.totalCount}</Badge>
                  </CardTitle>
                  <div className="selectDown">
                    <FormGroup>
                      <Label for="sortBy">Recent</Label>
                    </FormGroup>
                  </div>
                </Col>
                <Col>
                  <div className="filter">
                    <SearchFieldNav setSearchBy={setSearchBy} />

                    <div className="addNew">
                      <Link to="/admin/questions/create">
                        <Button color="primary">
                          Create new Personlization Questions
                          <i className="fa fa-plus"/>{" "}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody >
              {/* <div className="overflow-scroll-vertical" >
                {props.questions && props.questions.map((questions) => (
                  <div className="tpf-horizontal-card with-bg">
                      <Row className="my-3 p-3">
                          <Col lg="10" className="d-flex align-items-center ">
                              <div className="title">
                                  <h5 className="card-title font-weight-bold">{questions.title}</h5>
                                  <p>Total answers are {questions.answers.length}</p>
                              </div>
                          </Col>

                          <Col lg="2" className="d-flex justify-content-end">
                              <Button className="btn-icon" color="primary" size="sm" onClick={()=>{
                                  navigate('/admin/questions/edit/'+questions.id, { replace: true, state: questions })
                                }}
                              >
                                  <EditIcon />
                              </Button>
                              <Button className="btn-icon" color="warning" size="sm" onClick={()=>deleteClickHandler(questions.id)}>
                                <DeleteIcon />
                              </Button>
                          </Col>
                      </Row>
                  </div>
                ))}
              </div>

              <Button color="primary" className="btn-full" onClick={()=>{navigate('/admin/questions/create')}}>
                  Add a New Question
                  <i className="fa fa-plus ml-2"/>{" "}
              </Button> */}

              <Row>
                  {props.questions && props.questions.map((questions) => (
                    <Col lg="6" key={questions.id}>
                        <div className="tpf-horizontal-card p-3 with-bg my-2">
                            <Row>
                                <Col lg="10" className="d-flex align-items-center">
                                    <div className="title">
                                        <h5 className="card-title font-weight-bold">{questions.title}</h5>
                                        <p>Total answers are {questions.answers.length}</p>
                                    </div>
                                </Col>

                                <Col lg="2" className="d-flex justify-content-end">
                                    <Button className="btn-icon" color="primary" size="sm" onClick={()=>{
                                        navigate('/admin/questions/edit/'+questions.id, { replace: true, state: questions })
                                      }}>
                                        <EditIcon />
                                    </Button>
                                    <Button className="btn-icon" color="warning" size="sm" onClick={()=>deleteClickHandler(questions.id)}>
                                        <DeleteIcon />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                  ))}
              </Row>
              <Pagination 
                current_page={props.questions_pagination.currentPage}
                limit={props.questions_pagination.limit}
                previous={props.questions_pagination.previousPage}
                next={props.questions_pagination.nextPage}
                total_record={props.questions_pagination.totalCount}
                total_pages={props.questions_pagination.totalPages}
                setcurrentPageNumber={setcurrentPageNumber}
                search={searchBy}
              />
            </CardBody>
          </Card>
        </Col>
      </>
    )
}

const mapStateToProps = state => ({
  questions: state.questionsReducer.questions,
  questions_pagination: state.questionsReducer.questions_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  getQuestionsListAction: (data, limit, searchBy) => dispatch( getQuestionsListAction(data, limit, searchBy) ),
  QuestionsDeleteAction: (data, navigate) => dispatch( QuestionsDeleteAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions)