import * as t from "../types";

const defaultState = {
  chat_group:{},
  chat_groups: [],
  active_chat: false,
  chat_groups_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,},
};

const chatGroupReducer = (state = defaultState, action) => {
    switch (action.type) {
        case t.SET_CHAT_GROUP:
            return {
                ...state,
                chat_group: action.payload,
            };
        case t.SET_CHAT_GROUPS_LIST:
            return {
                ...state,
                chat_groups: action.payload,
            };
        case t.SET_CHAT_GROUPS_PAGINATION:
            return {
                ...state,
                chat_groups_pagination: action.payload,
            };
        case t.RESET_CHAT_GROUPS:
            return {
                    ...state,
                    chat_group: {},
                    chat_groups: [],
                    chat_groups_pagination: {
                        currentPage:1,
                        limit:10,
                        previousPage:null,
                        nextPage:2,
                        totalCount:0,
                        totalPages:1,
                    },
            };
        case t.ACTIVE_CHAT_MODULE: 
            return {
                ...state,
                active_chat: action.payload,
            }
        default:
        return state;
    }
}

export default chatGroupReducer;
