import * as t from "../types.js";
import { validationErrors } from "../../helper/ErrorHandler.js";
import APP_CONSTANTS from "../../constants/app.constant.js"
import axios from 'axios'

export const getBookedSessionListAction = (data, limit, searchBy) => async (dispatch, getState) => {
  let request_status = false;
  let return_data = [];

  dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
  });
  const config = {
      headers: { 
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id
      }
  };
  await axios.get( process.env.REACT_APP_API_URL +'/admin/pt-sessions?limit='+(limit ? limit : APP_CONSTANTS.DEFAULT_LIMIT_PER_PAGE)+(data ? '&page='+data:'')+(searchBy ? '&title='+searchBy:''), config).then((res) => {
      dispatch({
        type: t.SET_BOOKED_SESSIONS,
        payload: res.data.data,
      });
      dispatch({
        type: t.SET_BOOKED_SESSIONS_PAGINATION,
        payload: res.data.pagination,
      });
      return_data = res.data.data;
      request_status = true;
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
  }).catch((err) => {
      dispatch({
          type: t.SET_ERRORS,
          payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
      });
      dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
      });
  });
  
  if (request_status) return return_data;
  else return false;
};

export const bookedSessionViewAction = (data) => async (dispatch, getState) => {
  let request_status = false;
  let return_data = [];

  dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
  });
  const config = {
      headers: { 
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id
      }
  };
  await axios.get( process.env.REACT_APP_API_URL +'/admin/pt-sessions/'+data, config).then((res) => {
      dispatch({
        type: t.SET_BOOKED_SESSION,
        payload: res.data.data,
      });
      return_data = res.data.data;
      request_status = true;
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
  }).catch((err) => {
      dispatch({
          type: t.SET_ERRORS,
          payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
      });
      dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
      });
  });
  
  if (request_status) return return_data;
  else return false;
};

export const bookedSessionViewViaHashAction = (data) => async (dispatch, getState) => {
  let request_status = false;
  let return_data = [];

  dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
  });

  // const config = {
  //   headers: { 
  //     Authorization: `Bearer ${getState().userReducer.token}`,
  //     userid: getState().userReducer.id
  //   }
  // };

  await axios.get( process.env.REACT_APP_API_URL +'/pt-sessions/'+data).then((res) => {
      dispatch({
        type: t.SET_BOOKED_SESSION,
        payload: res.data.data,
      });
      return_data = res.data.data;
      request_status = true;
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
  }).catch((err) => {
      dispatch({
          type: t.SET_ERRORS,
          payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
      });
      dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
      });
  });
  
  if (request_status) return return_data;
  else return false;
};

export const bookedSessionUpdateStatusByTrainerAction = (id,data) => async (dispatch, getState) => {
  let request_status = false;
  let return_data = [];

  dispatch({
    type: t.SET_SHOW_LODER,
    payload: 1,
  });

  await axios.patch( process.env.REACT_APP_API_URL +'/pt-sessions/update-status/'+id,data).then((res) => {
      return_data = res.data.data;
      request_status = true;
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
  }).catch((err) => {
    validationErrors(err)
    dispatch({
      type: t.SET_ERRORS,
      payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
    });
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 0,
    });
  });
  
  if (request_status) return return_data;
  else return false;
};