import * as t from "../types";

const defaultState = {
  customer: [],
  customers: [],
  customers_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,
  },
  customers_task: [],
  customers_tasks: [],
  customers_tasks_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,
  },
  customers_subscription: [],
  customers_subscriptions: [],
  customers_subscriptions_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,
  },
};

const customersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case t.SET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case t.SET_CUSTOMERS_PAGINATION:
      return {
        ...state,
        customers_pagination: action.payload,
      };
      
    case t.SET_CUSTOMERS_TASK:
      return {
        ...state,
        customers_task: action.payload,
      };
    case t.SET_CUSTOMERS_TASKS:
      return {
        ...state,
        customers_tasks: action.payload,
      };
    case t.SET_CUSTOMERS_TASKS_PAGINATION:
      return {
        ...state,
        customers_tasks_pagination: action.payload,
      };
    
    case t.SET_CUSTOMERS_SUBSCRIPTION:
      return {
        ...state,
        customers_subscription: action.payload,
      };
    case t.SET_CUSTOMERS_SUBSCRIPTIONS:
      return {
        ...state,
        customers_subscriptions: action.payload,
      };
    case t.SET_CUSTOMERS_SUBSCRIPTIONS_PAGINATION:
      return {
        ...state,
        customers_subscriptions_pagination: action.payload,
      };
      
    case t.RESET_CUSTOMERS:
      return {
        ...state,
        customers: [],
        customers_pagination: {},
        customers_tasks: [],
        customers_tasks_pagination: {},
        currentPage:1,
        limit:10,
        previousPage:null,
        nextPage:2,
        totalCount:0,
        totalPages:1,
      };
    default:
      return state;
  }
}

export default customersReducer;
