import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    Row, 
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    FormFeedback,
} from "reactstrap";
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom";
import Discart from "../../components/Buttons/Discart";
import SaveAndPublish from "../../components/Buttons/SaveAndPublish";
import { trainingExpertiseUpdateAction,trainingExpertiseViewAction } from "../../redux/actions/trainingExpertiseAction";
import ImageUploder from "../../components/Uploders/ImageUploder";

function TrainingExpertiseEdit (props) {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    const [error, setError] = useState({});
    const [trainingExpertise, trainingExpertiseData] = useState({
        id:(location.state?location.state.id:0),
    });

    useEffect(()=>{
        props.trainingExpertiseViewAction(id).then(response => {});
    } , [])

    useEffect(()=>{
        if(props.errors) setError(props.errors);
    } , [props.errors])

    useEffect(()=>{
        if(props.training_expertise) {
            console.log(props.training_expertise,'CONSNSSNSNNS')
            trainingExpertiseData((currentState) => ({
            ...currentState,
            ...props.training_expertise,
        }));
        }
    } , [props.training_expertise])

    const handleChange = (e) => {
        trainingExpertiseData((currentState) => ({
        ...currentState,
        [e.target.name]: e.target.value
        }));
        setError({})
    }

    const formSubmitHandle = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        props.trainingExpertiseUpdateAction(formData, id, navigate).then(response => {});
    }
    return (
        <>
            <div className="content">
                <Card className="breadCrumb">
                    <CardBody>
                      <div className="tpf-horizontal-card">
                          <Row>
                              <Col xl="4">
                                  <div className="d-flex">
                                      <div>
                                          <Link to="/admin/plans">
                                          <figure className="figImage d-flex align-items-center">
                                              <svg width="50" height="50" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <circle opacity="0.2" cx="18" cy="18" r="18" fill="#4F76D9"/>
                                                  <path d="M13.6475 16.758C13.3486 17.0568 13.3486 17.5549 13.6475 17.8537L20.0889 24.3283C20.4209 24.6271 20.9189 24.6271 21.2178 24.3283L21.9814 23.5646C22.2803 23.2658 22.2803 22.7678 21.9814 22.4357L16.8682 17.2892L21.9814 12.176C22.2803 11.8439 22.2803 11.3459 21.9814 11.0471L21.2178 10.2834C20.9189 9.98456 20.4209 9.98456 20.0889 10.2834L13.6475 16.758Z" fill="white"/>
                                              </svg>
                                          </figure>
                                          </Link>
                                      </div>
                                      <div className="pl-3 flex-shrink-1 w-100 d-flex align-items-center">
                                          <div className="title">
                                              <h5 className="card-title font-weight-bold">Edit Training Expertise</h5>
                                              <ul className="breadCrumbList">
                                                  <li><a href="javascript:;" path="/admin/dashboard" >Home</a></li>
                                                  <li><a href="javascript:;" path={`/admin/training-experise`} >Training Expertise</a></li>
                                                  <li><a href="javascript:;" path={`/admin/training-experise`} >Edit</a></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </Col>

                              
                          </Row>
                      </div>
                    </CardBody>
                </Card>

                <Card className="guide-card">
                    <CardBody>
                        <form onSubmit={formSubmitHandle} encType="multipart/form-data">
                            <div className="form-row">
                            
                                 <FormGroup className="col-lg-6">
                                    <Label for="title">Title</Label>
                                    <Input type="text" name="title" id="title" value={trainingExpertise.title}
                                          invalid={error.title ? true:false} required onChange={handleChange} 
                                        />
                                    { error && <FormFeedback>{error.title}</FormFeedback> }
                                </FormGroup>
                                
                                <ImageUploder field_name={"image"} error={error} image_url={trainingExpertise.image}/>

                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end align-items-center w-100">
                                        <Discart status_field={true} back={"training-expertise/"} />
                                        <SaveAndPublish status_field={true} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
  training_expertise: state.trainingExpertiseReducer.training_expertise,
  errors: state.commonReducer.errors,
  show_loder: state.commonReducer.show_loder,
})

const mapDispatchToProps = (dispatch) => ({
    trainingExpertiseUpdateAction: (data,id, navigate) => dispatch( trainingExpertiseUpdateAction(data,id, navigate) ),
    trainingExpertiseViewAction: (id) => dispatch(trainingExpertiseViewAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingExpertiseEdit)