import * as t from "../types";

const defaultState = {
  package_price: [],
  package_prices: [],
  package_prices_list: [],
  package_prices_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,},
};

const packageTrainerPriceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_PACKAGE_PRICE:
      return {
        ...state,
        package_price: action.payload,
      };
    case t.SET_PACKAGE_PRICES:
      return {
        ...state,
        package_prices: action.payload,
      };
    case t.SET_ALL_PACKAGE_PRICES:
        return {
            ...state,
            package_prices_list: action.payload,
        };
    case t.SET_PACKAGE_PRICES_PAGINATION:
      return {
        ...state,
        package_prices_pagination: action.payload,
      };
    case t.RESET_PACKAGE_PRICES:
      return {
        ...state,
        package_price: [],
        package_prices: [],
        package_prices_list: [],
        package_prices_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default packageTrainerPriceReducer;
