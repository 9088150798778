import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { 
    Pagination, PaginationItem, PaginationLink,
} from "reactstrap";

function PaginationComponent(props) {
    const navigate = useNavigate();
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 10; // Adjust this value based on your preference
    
        let startPage = Math.max(1, props.current_page - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(props.total_pages, startPage + maxVisiblePages - 1);
    
        if (props.total_pages > maxVisiblePages) {
            // If there are more pages than maxVisiblePages, adjust the range
            if (endPage === props.total_pages) {
                startPage = Math.max(1, endPage - maxVisiblePages + 1);
            } else if (startPage === 1) {
                endPage = Math.min(props.total_pages, startPage + maxVisiblePages - 1);
            }
        }
    
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <PaginationItem key={i} active={i === props.current_page}>
                    <PaginationLink onClick={() => UpdatePageNumberFunction(i)}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return pageNumbers;
    };

    const UpdatePageNumberFunction = (value) => {
        props.setcurrentPageNumber(value);
        navigate(`?page=${value}&searchBy=${props.search}`,{replace: true});
    };

    const goToFirstPage = () => {
        UpdatePageNumberFunction(1);
        navigate(`?page=${1}&searchBy=${props.search}`,{replace: true});

    };

    const goToLastPage = () => {
        UpdatePageNumberFunction(props.total_pages);
        navigate(`?page=${props.total_pages}&searchBy=${props.search}`,{replace: true});

    };

    return (
        <div className="paging d-flex justify-content-center">
            <Pagination aria-label="pagination">
                <PaginationItem>
                    <PaginationLink previous onClick={() => UpdatePageNumberFunction(props.previous)}>
                        Previous
                    </PaginationLink>
                </PaginationItem>

                {renderPageNumbers()}

                <PaginationItem>
                    <PaginationLink next onClick={() => UpdatePageNumberFunction(props.next)}>
                        Next
                    </PaginationLink>
                </PaginationItem>

                <PaginationItem>
                    <PaginationLink onClick={goToFirstPage}>
                        First
                    </PaginationLink>
                </PaginationItem>

                <PaginationItem>
                    <PaginationLink onClick={goToLastPage}>
                        Last
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    );
}

export default PaginationComponent;