import React, {useState} from "react";
import Loder from "../../components/Loder/Loder";
import { 
  Button, FormGroup, Input
} from "reactstrap";
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom";

function SearchFieldNav (props) {
    const location = useLocation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(location.search);
    const initialSearch = urlParams.get("searchBy") ?? ""
    const [SearchValue, setSearchValue] = useState(initialSearch);

    const searchClickHandler = (e) => {
      if(SearchValue){
        props.setSearchBy(SearchValue)
      }
    }
    const timesClickHandler = (e) => {
      props.setSearchBy("")
      updateSearchParam("");
    }
    const searchKeyChangeHandler = (e) => {
      if (e.key === 'Enter') {
        if(SearchValue.length == 0) props.setSearchBy("0")
        else props.setSearchBy(SearchValue)
      }
    }

    const updateSearchParam = (value) => {
      // Update the URL with the new search parameter
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("searchBy", value);
  
      // Navigate to the updated URL
      navigate(`${location.pathname}?${searchParams.toString()}`);
  
      // Dispatch your action to handle the search in Redux, if needed
      props.setSearchBy(value);
    };

    return (
      <>
        <div className="searchBox">
          <FormGroup className="no-border">
            <Input type="text" placeholder="Search" value={SearchValue}
              // onChange={(e)=> searchChangeHandler(e)} 
              onChange={(e)=> setSearchValue(e.target.value)} 
              onKeyDown={searchKeyChangeHandler} 
            />
          </FormGroup>

          <Button color="link" className="btn-icon btn-round min-width-btn" onClick={()=> searchClickHandler()} >
            <i className="fa fa-search"></i>
          </Button>

          <Button color="link" className="btn-icon btn-round min-width-btn margin-right-btn" onClick={()=> timesClickHandler()} >
            <i className="fa fa-times"></i>
          </Button>
        </div>
      </>
    )
}

const mapStateToProps = state => ({
  show_loder: state.commonReducer.show_loder,
})

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFieldNav)