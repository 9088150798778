import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Container,
  FormFeedback,
  CardTitle,
  FormGroup,
  Label
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tpfLogo from "../../assets/img/tfp-logo.png"
import { bookedSessionViewViaHashAction, bookedSessionUpdateStatusByTrainerAction } from "../../redux/actions/bookedSessionAction";
import APP_CONSTANTS from "../../constants/app.constant";
import { swalNotification } from "../../components/Alerts/Notifications";

function SessionConfirmation(props) {
  const { hash } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [error, setError] = useState({});

  useEffect(() => {
    props.bookedSessionViewViaHashAction(hash);
  }, []);

  useEffect(() => {
    if (props.booked_session && props.booked_session.booking_date) {
      setStartDate(new Date(props.booked_session.booking_date));
    }
  }, [props.booked_session]);   

  const handleApprove = () => {
    if(booked_session.status === APP_CONSTANTS.BOOKED_SESSION_DEFAULT_STATUS)
      props.bookedSessionUpdateStatusByTrainerAction(hash,{status: 'approved', booking_date: startDate}).then(response => {
        swalNotification('Success','Thank you for your submission. For further information, please contact us at wecare@primefit.ae.',"success");
      });
  };

  const handleReject = () => {
    if(booked_session.status === APP_CONSTANTS.BOOKED_SESSION_DEFAULT_STATUS)
      props.bookedSessionUpdateStatusByTrainerAction(hash,{status: 'rejected'}).then(response => {
        swalNotification('Success','Thank you for your submission. For further information, please contact us at wecare@primefit.ae.',"success");
      });
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setError({})
  };

  useEffect(()=>{
    if(props.errors) setError(props.errors);
  } , [props.errors])

  const { booked_session } = props;
  return (
    <Container className="py-5">
      <Card className="p-4">
        <CardBody>
          <Container className="text-center mb-4">
            <img src={tpfLogo} alt="" className="mb-3" />
            <CardTitle tag="h4">
              Personal Training Session Request
            </CardTitle>
          </Container>
          <Row>
            <Col xl="8">
              <ul className="list-unstyled">
                <li className="text-muted">
                  <strong>Customer:</strong> <span style={{ color: "#8f8061" }}>{booked_session?.customer?.full_name}</span>
                </li>
                <li className="text-muted">{booked_session?.customer?.email}</li>
                <li className="text-muted">
                  <i className="fas fa-phone-alt" /> {booked_session?.customer?.phone_number}
                </li>
              </ul>
            </Col>
            <Col xl="4">
              <p className="text-muted">Session Details</p>
              <ul className="list-unstyled">
                <li className="text-muted">
                  <strong>Booking Date:</strong> {new Date(booked_session?.booking_date).toLocaleString()}
                </li>
                <li className="text-muted">
                  <strong>Package:</strong> { booked_session?.package?.name }
                </li>
                <li className="text-muted">
                  <strong>Total Amount:</strong>{booked_session?.total_amount}
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="col-lg-6">
                      <Label for="Date Time">Select Date & Time: </Label>
                      <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                    />
                    { error && <FormFeedback>{error.booking_date}</FormFeedback> }
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-4">
            {
              booked_session?.status === APP_CONSTANTS.BOOKED_SESSION_DEFAULT_STATUS
              ? 
              <Col> 
                <Button color="success" onClick={handleApprove} className="me-2">
                  Approve
                </Button>
                <Button color="danger" onClick={handleReject}>
                  Reject
                </Button> 
              </Col>
              : (
              <Col> 
                <CardTitle tag="h4" className="text-center">
                  It appears that you have already submitted your request. For additional information, please reach out to us at <strong>wecare@primefit.ae</strong>.
                </CardTitle>
              </Col>
              )
            }
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  booked_session: state.bookedSessionReducer.booked_session,
  errors: state.commonReducer.errors,
});

const mapDispatchToProps = (dispatch) => ({
  bookedSessionViewViaHashAction: (data) => dispatch(bookedSessionViewViaHashAction(data)),
  bookedSessionUpdateStatusByTrainerAction: (id,data) => dispatch(bookedSessionUpdateStatusByTrainerAction(id,data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionConfirmation);
