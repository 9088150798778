import * as t from "../types";

const defaultState = {
  section: [],
  sections: [],
  sections_list: [],
  sections_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,},
};

const sectionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_SECTION:
      return {
        ...state,
        section: action.payload,
      };
    case t.SET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };
    case t.SET_ALL_SECTIONS:
        return {
            ...state,
            sections_list: action.payload,
        };
    case t.SET_SECTIONS_PAGINATION:
      return {
        ...state,
        sections_pagination: action.payload,
      };
    case t.RESET_SECTIONS:
      return {
        ...state,
        section: [],
        sections: [],
        sections_list: [],
        sections_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default sectionReducer;
