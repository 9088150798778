
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Row,
  Table
} from "reactstrap";

import Moment from 'react-moment';
import { getCustomersSubscriptionsViewAction, CustomersSubscriptionCancelAction } from "../../../redux/actions/customersAction";
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom';

function CustomerSubscriptionsView(props) {
  const navigate = useNavigate()
  const { id } = useParams();

  useEffect(()=>{
    props.getCustomersSubscriptionsViewAction(id).then(response => {});
  } , [])

  const cancelSubscriptionClickHandler = (id) => {
    props.CustomersSubscriptionCancelAction(id).then(response => {
      // props.getCustomersSubscriptionsViewAction(id).then(response => {});
    });
  };

	return (
		<>
			<div className="content">
				<Card className="breadCrumb">
					<CardBody>
						<div className="tpf-horizontal-card">
							<div className="d-flex">
								<div>
                  <a href="javascript:;" onClick={()=>navigate(-1)}>
                      <figure className="figImage d-flex align-items-center">
                          <svg width="50" height="50" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle opacity="0.2" cx="18" cy="18" r="18" fill="#4F76D9"/>
                              <path d="M13.6475 16.758C13.3486 17.0568 13.3486 17.5549 13.6475 17.8537L20.0889 24.3283C20.4209 24.6271 20.9189 24.6271 21.2178 24.3283L21.9814 23.5646C22.2803 23.2658 22.2803 22.7678 21.9814 22.4357L16.8682 17.2892L21.9814 12.176C22.2803 11.8439 22.2803 11.3459 21.9814 11.0471L21.2178 10.2834C20.9189 9.98456 20.4209 9.98456 20.0889 10.2834L13.6475 16.758Z" fill="white"/>
                          </svg>
                      </figure>
                  </a>
								</div>

								<div className="pl-3 flex-shrink-1 w-100 d-flex align-items-center">
									<div className="title">
										<h5 className="card-title font-weight-bold">Customer Subscriptions</h5>
										<ul className="breadCrumbList">
											<li><a href="javascript:;" path="/admin/dashboard" >Home</a></li>
											<li><a href="javascript:;" path="/admin/Users" >Customer</a></li>
											<li><a href="javascript:;" path="/admin/Users" >Customer Profile</a></li>
											<li><a href="javascript:;" path="/admin/Users" >Customer Subscriptions</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			
				<Card className="profileCard">
					<CardBody>
						<Row>
              <Table responsive className="tablesorter tpf-table">
                <tbody>
                  <tr>
                    <td>Package Name</td>
                    <td>{props.customers_subscription?.package?.title}</td>
                  </tr>
                  <tr>
                    <td>Package Description</td>
                    <td dangerouslySetInnerHTML={{__html: props.customers_subscription?.package?.description}} />
                  </tr>
                  <tr>
                    <td>Package Price</td>
                    <td>{props.customers_subscription?.package?.price}</td>
                  </tr>
                  <tr>
                    <td>Package is Free</td>
                    <td>{props.customers_subscription?.package?.isFree ? "Yes":"No"}</td>
                  </tr>

                  <tr>
                    <td>Package Interval</td>
                    <td>{props.customers_subscription?.package?.interval}</td>
                  </tr>
                  <tr>
                    <td>Package Duration</td>
                    <td>{props.customers_subscription?.package?.duration}</td>
                  </tr>
                  <tr>
                    <td>Package Weight</td>
                    <td>{props.customers_subscription?.package?.weight}</td>
                  </tr>
                  <tr>
                    <td>Package Start_date</td>
                    <td>
                      {
                        props.customers_subscription?.start_date &&
                        <Moment format="DD/MMM/YYYY hh:ss:a">{props.customers_subscription?.start_date}</Moment>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Package End_date</td>
                    <td>
                      {
                        props.customers_subscription?.end_date && 
                        <Moment format="DD/MMM/YYYY hh:ss:a">{props.customers_subscription?.end_date}</Moment>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Package is Active</td>
                    <td>{props.customers_subscription?.is_active ? "Yes":"No"}</td>
                  </tr>
                  <tr>
                    <td>Package is Paid</td>
                    <td>{props.customers_subscription?.is_paid ? "Yes":"No"}</td>
                  </tr>
                  <tr>
                    <td>Package Stripe_id</td>
                    <td>{props.customers_subscription?.stripe_id}</td>
                  </tr>
                  <tr>
                    <td>Package is Canceled</td>
                    <td>{props.customers_subscription?.is_canceled ? "Yes":"No"}</td>
                  </tr>
                  {
                    (props.customers_subscription?.is_paid === true && props.customers_subscription?.is_canceled === false) &&
                    <tr>
                      <td>Canceled Subscription</td>
                      <td><Button color="danger" onClick={()=>cancelSubscriptionClickHandler(props.customers_subscription?.id)}>Cancel Subscription</Button></td>
                    </tr> 
                  }

                </tbody>
              </Table>
						</Row>
					</CardBody>
				</Card>
			</div>
		</>
	);
}

const mapStateToProps = state => ({
  errors: state.commonReducer.errors,
  customers_subscription: state.customersReducer.customers_subscription,
})

const mapDispatchToProps = (dispatch) => ({
  getCustomersSubscriptionsViewAction: (data) => dispatch( getCustomersSubscriptionsViewAction(data) ),
  CustomersSubscriptionCancelAction: (data) => dispatch( CustomersSubscriptionCancelAction(data) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSubscriptionsView)