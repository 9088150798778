import * as t from "../types";

const defaultState = {
  personal_trainer: [],
  personal_trainers: [],
  personal_trainers_list: [],
  personal_trainer_reviews: [],
  personal_trainers_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,
  },
  personal_trainer_reviews_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,
  },
};

const personalTrainerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_PERSONAL_TRAINER:
      return {
        ...state,
        personal_trainer: action.payload,
      };
    case t.SET_PERSONAL_TRAINERS:
      return {
        ...state,
        personal_trainers: action.payload,
      };
    case t.SET_ALL_PERSONAL_TRAINERS:
        return {
            ...state,
            personal_trainers_list: action.payload,
        };
    case t.SET_PERSONAL_TRAINERS_PAGINATION:
      return {
        ...state,
        personal_trainers_pagination: action.payload,
      };
    case t.SET_ALL_PERSONAL_TRAINER_REVIEWS:
      return {
        ...state,
        personal_trainer_reviews: action.payload,
      };
    case t.SET_PERSONAL_TRAINER_REVIEWS_PAGINATION:
      return {
        ...state,
        personal_trainer_reviews_pagination: action.payload,
      };
    case t.RESET_PERSONAL_TRAINERS:
      return {
        ...state,
        personal_trainer: [],
        personal_trainers: [],
        personal_trainers_list: [],
        personal_trainers_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
        personal_trainer_reviews: [],
        personal_trainer_reviews_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default personalTrainerReducer;
