import React from "react";
import { Link } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Badge,
    Table,
    Pagination, PaginationItem, PaginationLink,
} from "reactstrap";

function CalorieCalculations () {
    return (
        <>
            <div className="content">
				<Row>
					<Col md="12">
						<Card className="card-plain tpf-card">
							<CardHeader>
								<Row>
									<Col md="4">
										<CardTitle tag="h4">
                                            Calorie Data
											<Badge color="info" pill>8,945</Badge>
										</CardTitle>
										{/* <p className="category">Sort by:</p> */}
										<div className="selectDown">
											<FormGroup>
												<Label for="sortBy">Sort by:</Label>
												<Input type="select" name="sortBy" id="SortsortByBy" >
													<option>Recent</option>
													<option>...</option>
												</Input>
											</FormGroup>
										</div>
									</Col>

									<Col>
										<div className="filter">
											<div className="searchBox">
												<FormGroup className="no-border">
													<Input type="text" placeholder="Search"/>
												</FormGroup>

												<Button color="link" className="btn-icon btn-round">
													<i className="fa fa-search"></i>
												</Button>
											</div>

											<div className="addNew">
												<Link to="/admin/new-calorie-item">
													<Button color="primary">
														Create new Recipe
														<i className="fa fa-plus"/>{" "}
													</Button>
												</Link>
											</div>
										</div>
									</Col>
								</Row>
							</CardHeader>

							<CardBody>
								<Table responsive className="tablesorter tpf-table">
									<thead className="text-primary">
										<tr>
											<th>ID</th>
											<th>Title</th>
											<th>Type of Food</th>
											<th>Calories</th>
											<th>Calories per gram</th>
											<th>Uploaded</th>
											<th className="text-right">Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>
                                        
										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>

										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>
                                        
										<tr>
											<td>TPF-00951</td>
											<td>Espresso Coffee</td>
											<td>Drink</td>
											<td>400 kcal</td>
											<td>400 kcal</td>
											<td>12:49 PM - 26/Oct/2022</td>
											<td className="text-right">
                                                <Button className="btn-icon" color="info" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4095 0.610016L11.5111 2.71744C10.4701 1.49869 8.33724 0.483063 6.73763 0.483063H6.71224C3.63998 0.483063 1.07552 2.69205 0.542321 5.61197C0.542321 5.63736 0.542321 5.66275 0.542321 5.66275C0.542321 5.84048 0.669274 5.96744 0.847008 5.96744H2.09115C2.2181 5.96744 2.34506 5.86588 2.37045 5.73892C2.82748 3.86002 4.78256 2.33658 6.71224 2.33658C8.03256 2.33658 9.68295 3.19986 10.4193 4.29166L7.85482 4.1647H7.82943C7.67709 4.1647 7.52474 4.31705 7.52474 4.46939V5.68814C7.52474 5.84048 7.67709 5.99283 7.82943 5.99283H12.9329C13.0853 5.99283 13.2376 5.84048 13.2376 5.68814V0.584625C13.2376 0.432281 13.0853 0.279938 12.9329 0.279938H11.7142C11.5619 0.305328 11.4095 0.432281 11.4095 0.584625C11.4095 0.610016 11.4095 0.610016 11.4095 0.610016ZM6.71224 11.2487C5.39193 11.2487 3.74154 10.3854 3.00521 9.29361L5.59506 9.42056H5.62045C5.77279 9.42056 5.92513 9.26822 5.92513 9.11588V7.89713C5.92513 7.74478 5.77279 7.59244 5.62045 7.59244H0.542321C0.364586 7.59244 0.237633 7.74478 0.237633 7.89713V12.9753C0.237633 13.153 0.364586 13.2799 0.542321 13.2799H1.73568C1.88802 13.2799 2.04037 13.153 2.04037 12.9753L1.93881 10.8678C2.97982 12.0866 5.11263 13.0768 6.71224 13.0768C9.78451 13.0768 12.349 10.8932 12.9076 7.9733C12.9076 7.94791 12.9076 7.92252 12.9076 7.89713C12.9076 7.74478 12.7806 7.59244 12.6029 7.59244H11.3587C11.2318 7.61783 11.1048 7.71939 11.054 7.84634C10.597 9.72525 8.66732 11.2487 6.71224 11.2487Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="success" size="sm">
													<i>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M7.9504 2.66666L0.891808 9.72525L0.587121 12.6198C0.536339 13.0006 0.866417 13.3307 1.24728 13.2799L4.14181 12.9753L11.2004 5.91666L7.9504 2.66666ZM13.2063 2.18423L11.6828 0.660797C11.2258 0.178375 10.4387 0.178375 9.95626 0.660797L8.53439 2.08267L11.7844 5.33267L13.2063 3.9108C13.6887 3.42838 13.6887 2.64127 13.2063 2.18423Z" fill="white"/>
														</svg>
													</i>
												</Button>{` `}
												<Button className="btn-icon" color="warning" size="sm">
													<i>
														<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.4293 0.902313H8.38241L8.1285 0.445282C8.02694 0.242157 7.79842 0.0898132 7.5953 0.0898132H4.67538C4.47225 0.0898132 4.21835 0.242157 4.14217 0.445282L3.91366 0.902313H0.866784C0.638268 0.902313 0.460534 1.10544 0.460534 1.30856V2.12106C0.460534 2.34958 0.638268 2.52731 0.866784 2.52731H11.4293C11.6324 2.52731 11.8355 2.34958 11.8355 2.12106V1.30856C11.8355 1.10544 11.6324 0.902313 11.4293 0.902313ZM1.80624 11.9472C1.83163 12.582 2.39022 13.0898 3.02499 13.0898H9.24569C9.88046 13.0898 10.439 12.582 10.4644 11.9472L11.023 3.33981H1.27303L1.80624 11.9472Z" fill="white"/>
														</svg>
													</i>
												</Button>
											</td>
										</tr>
									</tbody>
								</Table>

                                <div className="paging d-flex justify-content-center">
                                    <Pagination aria-label="pagination">
                                        <PaginationItem>
                                            <PaginationLink previous href="#">
                                                Previous
                                            </PaginationLink>
                                        </PaginationItem>

                                        <div className="pages">
                                            Page 
                                            <a href="javascript:;"> 1 </a>
                                            of
                                            <a href="javascript:;"> 48 </a>
                                        </div>

                                        <PaginationItem>
                                            <PaginationLink next href="#">
                                                Next
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
							</CardBody>
						</Card>
					</Col>
				</Row>
            </div>
        </>
    )
}

export default CalorieCalculations;