import * as t from "../types";
import { validationErrors } from "../../helper/ErrorHandler";
import APP_CONSTANTS from "../../constants/app.constant.js";
import axios from "axios";

export const getCustomersListAction =
  (data, limit, searchBy) => async (dispatch, getState) => {
    let request_status = false;
    let return_data = [];
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/admin/customers?limit=" +
          10 +
          (data ? "&page=" + data : "") +
          (searchBy ? "&email=" + searchBy : ""),
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_CUSTOMERS,
          payload: res.data.data,
        });
        dispatch({
          type: t.SET_CUSTOMERS_PAGINATION,
          payload: res.data.pagination,
        });
        request_status = true;
        return_data = {
          customers: res.data.data,
          totalCount: res.data.pagination.totalCount,
          currentPage: res.data.pagination.currentPage,
          limit: res.data.pagination.limit,
          previousPage: res.data.pagination.previousPage,
          nextPage: res.data.pagination.nextPage,
          totalPages: res.data.pagination.totalPages,
        };
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      })
      .catch((err) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response
            ? err.response.data
            : err.response.data
            ? err.response.data.message
            : "",
        });
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      });
    if (request_status) return return_data;
    else return false;
  };

export const CustomersUpdateAction =
  (data, id, navigate) => async (dispatch, getState) => {
    let request_status = false;
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
        "content-type": "multipart/form-data",
      },
    };
    await axios
      .patch(
        process.env.REACT_APP_API_URL + "/admin/customer/" + id,
        data,
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: {},
        });
        request_status = true;
        navigate("/admin/customers");
      })
      .catch((err) => {
        validationErrors(err);
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response.data.data,
        });
      });
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 0,
    });
    if (request_status) return true;
    else return false;
  };

export const CustomersCreateAction =
  (data, navigate) => async (dispatch, getState) => {
    let request_status = false;
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
        "content-type": "multipart/form-data",
      },
    };

    await axios
      .post(process.env.REACT_APP_API_URL + "/admin/customer", data, config)
      .then((res) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: {},
        });
        request_status = true;
        navigate("/admin/customers");
      })
      .catch((err) => {
        validationErrors(err);
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response.data.data,
        });
      });
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 0,
    });
    if (request_status) return true;
    else return false;
  };

export const CustomersViewAction = (data) => async (dispatch, getState) => {
  let request_status = false;
  let return_data = [];

  dispatch({
    type: t.SET_SHOW_LODER,
    payload: 1,
  });
  const config = {
    headers: {
      Authorization: `Bearer ${getState().userReducer.token}`,
      userid: getState().userReducer.id,
    },
  };
  await axios
    .get(process.env.REACT_APP_API_URL + "/admin/customer/" + data, config)
    .then((res) => {
      dispatch({
        type: t.SET_CUSTOMER,
        payload: res.data.data,
      });
      return_data = res.data.data;
      request_status = true;
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
    })
    .catch((err) => {
      dispatch({
        type: t.SET_ERRORS,
        payload: err.response
          ? err.response.data
          : err.response.data
          ? err.response.data.message
          : "",
      });
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
    });

  if (request_status) return return_data;
  else return false;
};

export const CustomersDeleteAction =
  (data_id, navigate) => async (dispatch, getState) => {
    let request_status = false;
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
      },
    };
    await axios
      .delete(
        process.env.REACT_APP_API_URL + "/admin/customer/" + data_id,
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
        request_status = true;
        navigate("/admin/customers");
      })
      .catch((err) => {
        validationErrors(err);
      });
    if (request_status) return true;
    else return false;
  };

// Customers Task actions
export const getCustomersTasksListAction =
  (data, limit, searchBy) => async (dispatch, getState) => {
    let request_status = false;
    let return_data = [];

    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/admin/customer-tasks/" +
          data.customer_id +
          "?limit=" +
          (limit ? limit : APP_CONSTANTS.DEFAULT_LIMIT_PER_PAGE) +
          (data ? "&page=" + data.page : "") +
          (searchBy ? "&title=" + searchBy : ""),
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_CUSTOMERS_TASKS,
          payload: res.data.data,
        });
        dispatch({
          type: t.SET_CUSTOMERS_TASKS_PAGINATION,
          payload: res.data.pagination,
        });
        return_data = res.data.meta;
        request_status = true;
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      })
      .catch((err) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response
            ? err.response.data
            : err.response.data
            ? err.response.data.message
            : "",
        });
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      });

    if (request_status) return return_data;
    else return false;
  };
export const getCustomersTasksViewAction =
  (data) => async (dispatch, getState) => {
    let request_status = false;
    let return_data = [];

    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_API_URL + "/admin/customer-task/" + data,
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_CUSTOMERS_TASK,
          payload: res.data.data,
        });
        return_data = res.data.data;
        request_status = true;
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      })
      .catch((err) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response
            ? err.response.data
            : err.response.data
            ? err.response.data.message
            : "",
        });
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      });

    if (request_status) return return_data;
    else return false;
  };

// Customers Subscriptions actions
export const getCustomersSubscriptionsListAction =
  (data, limit, searchBy) => async (dispatch, getState) => {
    let request_status = false;
    let return_data = [];

    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/admin/customer-subscriptions?customerId=" +
          data.customer_id +
          "&limit=" +
          (limit ? limit : APP_CONSTANTS.DEFAULT_LIMIT_PER_PAGE) +
          (data ? "&page=" + data.page : "") +
          (searchBy ? "&title=" + searchBy : ""),
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_CUSTOMERS_SUBSCRIPTIONS,
          payload: res.data.data,
        });
        dispatch({
          type: t.SET_CUSTOMERS_SUBSCRIPTIONS_PAGINATION,
          payload: res.data.pagination,
        });
        return_data = res.data.meta;
        request_status = true;
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      })
      .catch((err) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response
            ? err.response.data
            : err.response.data
            ? err.response.data.message
            : "",
        });
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      });

    if (request_status) return return_data;
    else return false;
  };
export const getCustomersSubscriptionsViewAction =
  (data) => async (dispatch, getState) => {
    let request_status = false;
    let return_data = [];

    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_API_URL + "/admin/customer-subscription/" + data,
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_CUSTOMERS_SUBSCRIPTION,
          payload: res.data.data,
        });
        return_data = res.data.data;
        request_status = true;
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      })
      .catch((err) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response
            ? err.response.data
            : err.response.data
            ? err.response.data.message
            : "",
        });
        dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
        });
      });

    if (request_status) return return_data;
    else return false;
  };
export const CustomersSubscriptionCancelAction =
  (data, navigate) => async (dispatch, getState) => {
    let request_status = false;
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id,
        "content-type": "multipart/form-data",
      },
    };
    await axios
      .patch(
        process.env.REACT_APP_API_URL +
          "/admin/customer-subscription/" +
          data +
          "/cancel",
        [],
        config
      )
      .then((res) => {
        dispatch({
          type: t.SET_ERRORS,
          payload: {},
        });
        request_status = true;
        // navigate('/admin/customers')
      })
      .catch((err) => {
        validationErrors(err);
        dispatch({
          type: t.SET_ERRORS,
          payload: err.response.data.data,
        });
      });
    dispatch({
      type: t.SET_SHOW_LODER,
      payload: 0,
    });
    if (request_status) return true;
    else return false;
  };
