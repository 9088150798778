import React, { useState, useEffect, useRef } from "react";
import notificationSound from '../../assets/sound/notification_tune.mp3';
import * as t from "../../redux/types.js";
import { useDispatch } from 'react-redux';
import {  Card, CardBody, CardHeader, CardTitle, Row, Col, Button, FormGroup, Label, Input, Badge } from "reactstrap";
import anime3 from '../../assets/img/anime3.png';
import { getAllChatGroupsList, endChatAction, acceptChatAction, sendMessageAction, fetchChatGroupAction, markChatAsReadyAction } from "../../redux/actions/chatGroupsAction";
import { connect } from "react-redux"
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";
import APP_CONSTANTS from "../../constants/app.constant";
import pusherService from "../../service/pusherService";
import InfiniteScroll from 'react-infinite-scroll-component';

function ChatSupport (props) {
    const dispatch = useDispatch();
    const notificationRef = useRef(null);
    const scrollerRef = useRef(null);
    const [sortBy, setSortBy] = useState(false);
    const [ message, setMessage] = useState('');
    const [searchBy, setSearchBy] = useState("");
    const [scrollDirection, setScrollDirection] = useState('down');
    const [currentPageNumber, setcurrentPageNumber] = useState(1);

    useEffect(()=>{
        let search = null;
        let page = currentPageNumber;
        if(sortBy || searchBy) {
            search = { title: searchBy, ...sortBy}
            page = 1;
        }
        if(currentPageNumber) props.getAllChatGroupsList(page,100,search).then(response => {});
        dispatch({
            type: t.ACTIVE_CHAT_MODULE,
            payload: false,
        });
    },[currentPageNumber,searchBy,sortBy]);

    const handleChatGroupClick = (chatGroup) => {
        props.fetchChatGroupAction(chatGroup.id).then(response =>  { 
            if(response.started === true && response.unread_messages_count > 0) {
                markChatAsRead(chatGroup);
            }
        });
    };

    const markChatAsRead = (chatGroup) => {
        props.markChatAsReadyAction(chatGroup.id).then(response => {});
        return true
    }
    
    const getClassName = (message,chatGroup) => {
        let className = '';
        if(message.sender_id === chatGroup.customers[0].id) {
            className = "messageTo d-flex justify-content-start"
        }
        if( chatGroup.users.length > 0 && message.sender_id === chatGroup.users[0].id) {
            className = "messageFrom d-flex justify-content-end"
        }
        return className
    }

    const getSenderName = (message,chatGroup) => {
        let sendersInfo = null;
        sendersInfo = chatGroup?.customers.find(value => value.id === message.sender_id)
        if(!sendersInfo) {
            sendersInfo = chatGroup?.users.find(value => value.id === message.sender_id);
        }
        return sendersInfo;
    }

    const endChat = async (chatGroup) => {
        props.endChatAction(chatGroup).then(response => {});
        return true
    }

    const acceptChat = async(chatGroup) => {
        props.acceptChatAction({type:'accept'},chatGroup.id).then(response => {});
        return true
    }

    const sendMessage = (e) => {
        e.preventDefault();
        if(message.length > 0) {
            let payload = {
                content: message,
                chat_group_id: props.chat_group.id
            }
            props.sendMessageAction(payload).then(response => {});
            setMessage('');
        }
        return true
    };

    const handleSortByChange = (event) => {
        setSortBy({sortBy:event.target.value == 1 ? true : false});
    };

    useEffect(() => {
        const channelNames = APP_CONSTANTS.CHAT_CHANNEL;
        pusherService.subscribeToChannels(channelNames, APP_CONSTANTS.NEW_CHAT_EVENT, (channelName, data) => {
            const newChatGroup = data.message;
            if(newChatGroup.users.length < 1) {
                const existingChatGroups = props.chat_groups;
                const updatedChatGroups = [newChatGroup, ...existingChatGroups];
                dispatch({
                    type: t.SET_CHAT_GROUPS_LIST,
                    payload: updatedChatGroups,
                });
                playNewMessageSound();
            }
        });

        pusherService.subscribeToChannels(channelNames, APP_CONSTANTS.NEW_MESSAGE, (channelName, data) => {
            const newMessage = data.message;
            const currentChatGroups = props.chat_groups;
            const currentChatGroup = props.chat_group
            if(newMessage.chat_group_id === currentChatGroup.id) {
                const updatedChatGroup = {
                    ...currentChatGroup,
                    chat_messages: [...currentChatGroup.chat_messages, newMessage]
                };
            
                dispatch({
                    type: t.SET_CHAT_GROUP,
                    payload: updatedChatGroup,
                });
                markChatAsRead(updatedChatGroup);
            }
            else {
                const indexOfUpdatedChatGroup = currentChatGroups.findIndex(chatGroup => chatGroup.id === newMessage.chat_group_id);
                if (indexOfUpdatedChatGroup !== -1) {
                    const updatedChatGroups = [...currentChatGroups];
                    updatedChatGroups[indexOfUpdatedChatGroup] = {
                        ...updatedChatGroups[indexOfUpdatedChatGroup],
                        unread_messages_count : updatedChatGroups[indexOfUpdatedChatGroup].unread_messages_count + 1,
                    };
                    dispatch({
                        type: t.SET_CHAT_GROUPS_LIST,
                        payload: updatedChatGroups,
                    });
                    playNewMessageSound();
                }        
            }  
        });
        return () => {
            pusherService.unsubscribeToChannels(APP_CONSTANTS.CHAT_CHANNEL)
        };
    },[props.chat_groups, props.dispatch]);

    const playNewMessageSound = () => {
        if (notificationRef.current) {
            const audioElement = notificationRef.current;
            audioElement.play().catch(error => {
                console.error('Failed to play notification sound:', error);
                document.body.addEventListener("mousemove", function () {
                    audioElement.play()
                })  
            });
        }
        return true
    };

    const fetchMoreData = () => {
        const nextPageNumber = scrollDirection === 'up' ? currentPageNumber - 1 : currentPageNumber + 1;
        setcurrentPageNumber(nextPageNumber);
    };

    return (
        <>
            <div className="content">
                <Row>
                    <div>
                        <audio ref={notificationRef} src={notificationSound}/>
                    </div>
                    <Col md="12">
                        <Card className="card-plain tpf-card">
                            <CardHeader>
                                <Row>
                                    <Col md="4">
                                        <CardTitle tag="h4">
                                            Chat Support
                                        </CardTitle>
                                        <div className="selectDown">
                                            <FormGroup>
                                                <Label for="sortBy">Sort by:</Label>
                                                <Input type="select" name="sortBy" id="sortBy" onChange={handleSortByChange}>
                                                    <option value="0" selected={sortBy == false ? true : false}>Opened</option>
                                                    <option value="1" selected={sortBy == true ? true : false}>Closed</option>
                                                </Input>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>

                                <Col>
                                    <div className="filter">
                                        <SearchFieldNav setSearchBy={setSearchBy} />
                                    </div>
                                </Col>
                            </CardHeader>

                            <CardBody>
                                <Row>
                                    <Col lg="3">
                                        {/* <div className="SearchContact mb-2">
                                            <form>
                                                <Row>
                                                    <Col lg="9">
                                                        <Input type="text" placeholder="First name" />
                                                    </Col>
                                                    <Col lg="3">
                                                        <Button className="btn-link" color="white">
                                                            <i className="fa fa-search"></i>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div> */}
                                    
                                        <div className="contactList" >
                                            <InfiniteScroll
                                                dataLength={props.chat_groups.length} // length of your data array
                                                next={fetchMoreData}
                                                hasMore={currentPageNumber < props.chat_groups_pagination.totalPages} // set to true to enable infinite scroll
                                                scrollableTarget = {scrollerRef}
                                                inverse={true}
                                            >
                                                <div ref={scrollerRef}>
                                                    <ul>
                                                        {props.chat_groups.map((chat_group) => (
                                                            <li className={`mb-2 ${chat_group.id === props.chat_group?.id ? 'active' : 'inactive'}`} key={chat_group.id} onClick={() => {
                                                                handleChatGroupClick(chat_group);
                                                            }}>
                                                                <div className="contact">
                                                                    <figure>
                                                                        <img src={anime3} alt="..."/>
                                                                    </figure>

                                                                    <div className="contactname">
                                                                        <h4>
                                                                            {chat_group.customers[0]?.fullName}
                                                                            {chat_group?.unread_messages_count && chat_group?.unread_messages_count  > 0 && chat_group?.id !== props.chat_group?.id ? (
                                                                                <Badge color="primary">{chat_group.unread_messages_count}</Badge>
                                                                            ) : null}
                                                                            {chat_group.started === false && chat_group.closed === false ? (
                                                                                <Badge color="danger">pending</Badge>
                                                                            ) : null}
                                                                        </h4>
                                                                        <p>{chat_group.created_at}</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </InfiniteScroll>
                                        </div>
                                    </Col>
                                   
                                        <Col lg="9">
                                            {props.chat_group && Object.keys(props.chat_group).length > 0 ? (
                                                <div className="chatWindow">
                                                    <div className="cw-header">
                                                        <figure>
                                                            <img src={anime3} alt="..."/>
                                                        </figure>

                                                        <div className="infoColumn d-flex flex-column">
                                                            <label className="text-center">Email</label>
                                                            <a href={props.chat_group.customers[0].email} className="text-center" target="_blank">
                                                            {props.chat_group.customers[0].email}
                                                            </a>
                                                        </div>
                                                        <div className="infoColumn d-flex flex-column">
                                                            {props.chat_group.closed === false && props.chat_group.started === true ? (
                                                                <Button className="btn" color="danger" onClick={() => endChat(props.chat_group)}>End Chat</Button>
                                                            ) : props.chat_group.closed === false && props.chat_group.started === false ?  
                                                            <Button className="btn" color="success" onClick={() => acceptChat(props.chat_group)}>Accept Chat</Button> : null}
                                                        </div>
                                                    </div>

                                                    <div className="cw-body">
                                                        <div className="messageWindow">
                                                            {props.chat_group?.chat_messages.map((message) => (
                                                                <div className={getClassName(message,props.chat_group)}>
                                                                    <div className="messageBody">
                                                                        <h4>
                                                                            { getSenderName(message,props.chat_group)?.fullName }
                                                                            <span className="time">{message.created_at}</span>
                                                                        </h4>
                                                                        <div className="messageHere">
                                                                            <p>
                                                                            {message.content}
                                                                            </p> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {props.chat_group.started === true ? (
                                                            <div className="meeseageSend">
                                                                <form onSubmit={sendMessage}>
                                                                    <Input type="text" placeholder="Type here"   value={message}
                                                                    onChange={(e) => setMessage(e.target.value)}/>
                                                                    
                                                                    <Button color="primary">
                                                                        <i className="fa fa-paper-plane" type='submit'></i>
                                                                    </Button>
                                                                </form>
                                                            </div>
                                                        ) : ''}
                                                    </div>
                                                </div>
                                            ) : ''}
                                        </Col>
                                  
                                </Row>
                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    chat_groups: state.chatGroupReducer.chat_groups,
    chat_groups_pagination: state.chatGroupReducer.chat_groups_pagination,
    chat_group: state.chatGroupReducer.chat_group,
})

const mapDispatchToProps = (dispatch) => ({
    
    getAllChatGroupsList: (data, limit, searchBy) => dispatch( getAllChatGroupsList(data, limit, searchBy) ),
    endChatAction: (data) => dispatch( endChatAction(data) ),
    acceptChatAction: (data,id) => dispatch(acceptChatAction(data,id)),
    sendMessageAction: (message,id) => dispatch(sendMessageAction(message,id)),
    fetchChatGroupAction: (id) => dispatch(fetchChatGroupAction(id)),
    markChatAsReadyAction: (id) => dispatch(markChatAsReadyAction(id))
    
});
export default connect(mapStateToProps, mapDispatchToProps)(ChatSupport);