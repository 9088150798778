import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    Table,
    Badge,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { getTrainerPricesListAction, trainerPricesDeleteAction } from "../../redux/actions/packageTrainerPriceAction";
import { connect } from "react-redux"
import Pagination from "../../components/Pagination/Pagination";
import EditIcon from "../../components/Icons/EditIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
// import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";

function PackageTrainerPrice (props) {
    const navigate = useNavigate();
    const { packageId } = useParams();
    const [currentPageNumber, setcurrentPageNumber] = useState(1);
    const [searchBy, setSearchBy] = useState("");

    useEffect(()=>{
      if(currentPageNumber) props.getTrainerPricesListAction(currentPageNumber,"","",packageId).then(response => {});
    },[currentPageNumber]);

    useEffect(()=>{
      if(searchBy === "0") {
        props.getTrainerPricesListAction(1, "", "",packageId).then(response => {});
      }else if(searchBy) {
        props.getTrainerPricesListAction(1, "", searchBy,packageId).then(response => {});
      }
    },[searchBy]);

    const deleteClickHandler = (id) => {
      props.trainerPricesDeleteAction(id).then(response => {
        if(response) props.getTrainerPricesListAction(currentPageNumber,"","",packageId);
      });
    }
    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-plain tpf-card">
                <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">
                          Package Trainer Prices
                          <Badge color="info" pill>{props.package_trainer_prices_pagination?.totalCount}</Badge>
                        </CardTitle>
                        {/* <p className="category">Sort by:</p> */}
                        <div className="selectDown">
                          <FormGroup>
                            <Label for="sortBy">Sort by:</Label>
                            <Input type="select" name="sortBy" id="SortsortByBy" >
                              <option>Recent</option>
                              <option>...</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </Col>

                      <Col>
                        <div className="filter">
                          <SearchFieldNav setSearchBy={setSearchBy} />

                          <div className="addNew">
                            <Link to={`/admin/package-trainer-prices/create/${packageId}`}>
                              <Button color="primary">
                                Create new Package Trainer Price
                                <i className="fa fa-plus"/>{" "}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Table responsive className="tablesorter tpf-table">
                      <thead className="text-primary">
                        <tr>
                          <th>ID</th>
                          <th>Package</th>
                          <th>Trainer</th>
                          <th>Price</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props?.package_prices?.map((package_trainer_price) => (
                          <tr key={package_trainer_price.id}>
                            <td>{package_trainer_price.id}</td>
                            <td>{package_trainer_price.package.title}</td>
                            <td>{package_trainer_price?.trainer.name}</td>
                            <td>{package_trainer_price?.price}</td>
                            <td className="text-right">
                              <Button className="btn-icon" color="success" size="sm" onClick={()=> {
                                navigate('/admin/package-trainer-prices/edit/'+package_trainer_price.id, { replace: true, state: package_trainer_price })
                              }}>
                                <EditIcon />
                              </Button>{` `}
                              <Button className="btn-icon" color="warning" size="sm" onClick={()=>deleteClickHandler(package_trainer_price.id)}>
                                <DeleteIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination 
                      current_page={props?.package_trainer_prices_pagination?.currentPage}
                      limit={props?.package_trainer_prices_pagination?.limit}
                      previous={props?.package_trainer_prices_pagination?.previousPage}
                      next={props?.package_trainer_prices_pagination?.nextPage}
                      total_record={props?.package_trainer_prices_pagination?.totalCount}
                      total_pages={props?.package_trainer_prices_pagination?.totalPages}
                      setcurrentPageNumber={setcurrentPageNumber}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

const mapStateToProps = state => ({
  package_prices: state.packageTrainerPriceReducer.package_prices,
  package_prices_pagination: state.packageTrainerPriceReducer.package_prices_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  getTrainerPricesListAction: (data, limit, searchBy,packageId) => dispatch( getTrainerPricesListAction(data, limit, searchBy,packageId) ),
  trainerPricesDeleteAction: (data, navigate) => dispatch( trainerPricesDeleteAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageTrainerPrice)