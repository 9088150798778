import * as t from "../types.js";
import { validationErrors } from "../../helper/ErrorHandler.js";
import APP_CONSTANTS from "../../constants/app.constant.js"
import axiosInstance from '../../service/axiosIntance.js';

export const getAllChatGroupsList = (data, limit, searchBy) => async (dispatch, getState) => {
    let request_status = false;
    let return_data = [];
    let queryString = '';
    if(searchBy) {
        if(searchBy.title) {
            queryString = `&title=${searchBy.title}`
        }
        if(searchBy.sortBy) {
            queryString += `&closed=${searchBy.sortBy}`
        }
    }
    dispatch({ 
        type: t.SET_SHOW_LODER,
        payload: 1,
    });
    const config = {
        headers: { 
            Authorization: `Bearer ${getState().userReducer.token}`,
            userid: getState().userReducer.id
        }
    };
    await axiosInstance.get( process.env.REACT_APP_API_URL +'/admin/chat-groups'+(data ? '?page='+data:'')+(limit ? '&limit='+APP_CONSTANTS.DEFAULT_LIMIT_PER_PAGE: '')+(queryString), config).then((res) => {
        let updatedChatGroups =  [];
        console.log(searchBy && searchBy.hasOwnProperty('sortBy'),'HELLLOOOO');
        if(searchBy && searchBy.hasOwnProperty('sortBy') ) {
            console.log('yes');
            updatedChatGroups = [
                ...res.data.data
            ];
        }
        else {
            const currentChatGroups = getState().chatGroupReducer.chat_groups;
            updatedChatGroups =  [ ...currentChatGroups,
                ...res.data.data
            ];
        }
        dispatch({
            type: t.SET_CHAT_GROUPS_LIST,
            payload: updatedChatGroups
        });
        dispatch({
            type: t.SET_CHAT_GROUPS_PAGINATION,
            payload: res.data.pagination,
        });
        return_data = res.data.meta;
        request_status = true;
        dispatch({
            type: t.SET_SHOW_LODER,
            payload: 0,
        });
    }).catch((err) => {
        dispatch({
            type: t.SET_ERRORS,
            payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
        });
        dispatch({
            type: t.SET_SHOW_LODER,
            payload: 0,
        });
    });
  
    if (request_status) return return_data;
    else return false;
};

export const endChatAction = (data) => async (dispatch, getState) => {
    let request_status = false;
    let return_data = [];
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 1,
    });
    const config = {
        headers: { 
            Authorization: `Bearer ${getState().userReducer.token}`,
            userid: getState().userReducer.id
        }
    };
    await axiosInstance.patch( `${process.env.REACT_APP_API_URL}/admin/chat-group/${data.id}/closed`,{closed:true},config).then((res) => {
        return_data = res.data.data;
        request_status = true;
        dispatch({
            type: t.SET_SHOW_LODER,
            payload: 0,
        });
        const currentChatGroups = getState().chatGroupReducer.chat_groups;
        const updatedChatGroups = currentChatGroups.filter(chatGroup => chatGroup.id !== data.id);
        dispatch({
            type: t.SET_CHAT_GROUPS_LIST,
            payload: updatedChatGroups,
        });
        dispatch({
            type: t.SET_CHAT_GROUP,
            payload: {},
        });
    }).catch((err) => {
        console.log(err);
        dispatch({
            type: t.SET_ERRORS,
            payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
        });
        dispatch({
            type: t.SET_SHOW_LODER,
            payload: 0,
        });
    });
  
    if (request_status) return return_data;
    else return false;
};

export const acceptChatAction = (data, id, navigate) => async (dispatch, getState) => {
    let request_status = false;
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 1,
    });
    const config = {
        headers: { 
            Authorization: `Bearer ${getState().userReducer.token}`,
            userid: getState().userReducer.id
        } 
    };

    await axiosInstance.patch(`${process.env.REACT_APP_API_URL}/admin/chat-group/request/${id}`, data, config).then((res) => {
        dispatch({
            type: t.SET_ERRORS,
            payload: {},
        });
        let response = res?.data?.data
        const currentChatGroups = getState().chatGroupReducer?.chat_groups;
        const indexOfUpdatedChatGroup = currentChatGroups.findIndex(chatGroup => chatGroup.id === response.id);
        const acceptedChatGroup = getState().chatGroupReducer.chat_group;
        if (indexOfUpdatedChatGroup !== -1) {
            const updatedChatGroups = [...currentChatGroups];
            updatedChatGroups[indexOfUpdatedChatGroup] = response;
            dispatch({
                type: t.SET_CHAT_GROUPS_LIST,
                payload: updatedChatGroups,
            });
        }
        const updatedChatGroup = { ...acceptedChatGroup,  started: true};
        dispatch({
            type: t.SET_CHAT_GROUP,
            payload: updatedChatGroup,
        });
        request_status = true;
    }).catch((err) => {
            validationErrors(err)
            dispatch({
            type: t.SET_ERRORS,
            payload: err.response.data.data,
        });
    });
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
    });
    if (request_status) return true;
    else return false;
};

export const fetchChatGroupAction = (id,navigate) => async (dispatch, getState) => {
    let request_status = false;
    let response = null
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 1,
    });

    const config = {
        headers: { 
            Authorization: `Bearer ${getState().userReducer.token}`,
            userid: getState().userReducer.id
        } 
    };

    await axiosInstance.get(`${process.env.REACT_APP_API_URL}/admin/chat-group/${id}`, config).then((res) => {
        dispatch({
            type: t.SET_ERRORS,
            payload: {},
        });
        response = res?.data?.data
        dispatch({
            type: t.SET_CHAT_GROUP,
            payload: response,
        });
        request_status = true;
    }).catch((err) => {
        validationErrors(err)
        dispatch({
            type: t.SET_ERRORS,
            payload: err.response.data.data,
        });
    });
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
    });
    if (request_status) return response;
    else return false;
};

export const sendMessageAction = (data,navigate) => async (dispatch, getState) => {
    let request_status = false;
    let response = null
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 1,
    });

    const config = {
        headers: { 
            Authorization: `Bearer ${getState().userReducer.token}`,
            userid: getState().userReducer.id
        } 
    };

    await axiosInstance.post(`${process.env.REACT_APP_API_URL}/admin/chat-message`,data, config).then((res) => {
        dispatch({
            type: t.SET_ERRORS,
            payload: {},
        });
        response = res?.data?.data
  
        const currentChatGroup = getState().chatGroupReducer.chat_group;
        let updatedChatGroup = {
            ...currentChatGroup,
            chat_messages: [...currentChatGroup.chat_messages,response ],
        };
        dispatch({
            type: t.SET_CHAT_GROUP,
            payload: updatedChatGroup,
        });
        request_status = true;
    }).catch((err) => {
        validationErrors(err)
        dispatch({
            type: t.SET_ERRORS,
            payload: err.response.data.data,
        });
    });
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
    });
    if (request_status) return response;
    else return false;
};

export const markChatAsReadyAction = (chatGroupId,navigate) => async (dispatch, getState) => {
    let request_status = false;
    let response = null
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 1,
    });

    const config = {
        headers: { 
            Authorization: `Bearer ${getState().userReducer.token}`,
            userid: getState().userReducer.id
        } 
    };

    await axiosInstance.put(`${process.env.REACT_APP_API_URL}/admin/chat-message/mark-as-read/${chatGroupId}`,[], config).then((res) => {
        dispatch({
            type: t.SET_ERRORS,
            payload: {},
        });
        response = res?.data?.data
        const currentChatGroup = getState().chatGroupReducer.chat_group;
        const currentChatGroups = getState().chatGroupReducer.chat_groups;
        let updatedChatGroup = {...currentChatGroup,  chat_messages: [...response], unread_message_count: 0};
        const indexOfUpdatedChatGroup = currentChatGroups.findIndex(chatGroup => chatGroup.id === currentChatGroup.id);
        if (indexOfUpdatedChatGroup !== -1) {
            const updatedChatGroups = [...currentChatGroups];
            updatedChatGroups[indexOfUpdatedChatGroup] = {
                ...updatedChatGroups[indexOfUpdatedChatGroup],
                unread_messages_count : 0,
            };
            dispatch({
                type: t.SET_CHAT_GROUPS_LIST,
                payload: updatedChatGroups,
            });
        }
        dispatch({
            type: t.SET_CHAT_GROUP,
            payload: {...updatedChatGroup},
        });
        request_status = true;
    }).catch((err) => {
        validationErrors(err)
        dispatch({
            type: t.SET_ERRORS,
            payload: err.response.data.data,
        });
    });
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
    });
    if (request_status) return response;
    else return false;
};