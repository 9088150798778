import * as t from "../types";

const defaultState = {
  ramazan_task: [],
  ramazan_tasks: [],
  ramazan_tasks_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,
  },
  page_number_ramazan_task: 1,
};

const ramazanTasksReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_RAMAZAN_TASK:
      return {
        ...state,
        ramazan_task: action.payload,
      };
    case t.SET_RAMAZAN_TASKS:
      return {
        ...state,
        ramazan_tasks: action.payload,
      };
    case t.SET_RAMAZAN_TASKS_PAGINATION:
      return {
        ...state,
        ramazan_tasks_pagination: action.payload,
      };
    case t.RESET_RAMAZAN_TASKS:
      return {
        ...state,
        ramazan_task: [],
        ramazan_tasks: [],
        ramazan_tasks_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    case t.SET_PAGE_NUMBER_RAMAZAN_TASK:
      return {
        ...state,
        page_number_ramazan_task: action.payload,
      };
    default:
      return state;
  }
}

export default ramazanTasksReducer;
