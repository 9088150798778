// pusherHandler.js
import Pusher from 'pusher-js';
import APP_CONSTANTS from '../constants/app.constant';

const pusherConfig = {
  key: APP_CONSTANTS.PUSHER_KEY,
  cluster: APP_CONSTANTS.PUSHER_CLUSTER,
};

Pusher.logToConsole = false;

const pusher = new Pusher(pusherConfig.key, {
  cluster: pusherConfig.cluster,
});

const subscribeToChannels = (channelName, eventName, callback) => {
    const channel = pusher.subscribe(channelName);

    // Listen for events on the channel
    channel.bind(eventName, (data) => {
    // Perform actions based on the received data
      callback(channelName, data);
    });
};

const unsubscribeToChannels = (channelName) => {
  pusher.unsubscribe(channelName);
};

let pusherService = {
  subscribeToChannels,
  unsubscribeToChannels
}
export default pusherService;
