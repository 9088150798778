import * as t from "../types";

const defaultState = {
  notification: [],
  notifications: [],
  notifications_list: [],
  notifications_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,},
};

const notificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case t.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case t.SET_ALL_NOTIFICATIONS:
        return {
            ...state,
            notifications_list: action.payload,
        };
    case t.SET_NOTIFICATIONS_PAGINATION:
      return {
        ...state,
        notifications_pagination: action.payload,
      };
    case t.RESET_NOTIFICATIONS:
      return {
        ...state,
        notification: [],
        notifications: [],
        notifications_list: [],
        notifications_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default notificationReducer;
