import { combineReducers } from "redux";
import userReducer from "./userReducer";
import commonReducer from "./commonReducer";
import usersReducer from "./usersReducer";
import customersReducer from "./customersReducer";
import helpfulTipsReducer from "./helpfulTipsReducer";
import homeCategoriesReducer from "./homeCategoriesReducer";
import applicationSettingsReducer from "./applicationSettingsReducer";
import promotionBannersReducer from "./promotionBannersReducer";
import questionsReducer from "./questionsReducer";
import entityAndAttributesReducer from "./entityAndAttributesReducer";
import plansReducer from "./plansReducer";
import mealsReducer from "./mealsReducer";
import foodTypesReducer from "./foodTypesReducer";
import calorieCalculationsReducer from "./calorieCalculationsReducer";
import workoutsReducer from "./workoutsReducer";
import medicalInstructionsReducer from "./medicalInstructionsReducer";
import contentOfTheDaysReducer from "./contentOfTheDaysReducer";
import dailyTasksReducer from "./dailyTasksReducer";
import milestonesReducer from "./milestonesReducer";
import ramazanContentOfTheDaysReducer from "./ramazanContentOfTheDaysReducer";
import ramazanTasksReducer from "./ramazanTasksReducer";
import ramazanMilestonesReducer from "./ramazanMilestonesReducer";
import chatGroupReducer from "./chatGroupReducer";
import sectionReducer from "./SectionReducer";
import personalTrainerReducer from "./PersonalTrainerReducer";
import packageTrainerPriceReducer from "./packageTrainerPriceReducer";
import trainingExpertiseReducer from "./trainingExpertiseReducer";
import bookedSessionReducer from "../BookedSessionReducer";
import notificationReducer from "./notificationReducer";
import faqsReducer from "./faqsReducer";

const rootReducer = combineReducers({
  userReducer: userReducer,
  customersReducer: customersReducer,
  commonReducer: commonReducer,
  usersReducer: usersReducer,
  helpfulTipsReducer: helpfulTipsReducer,
  homeCategoriesReducer: homeCategoriesReducer,
  applicationSettingsReducer: applicationSettingsReducer,
  promotionBannersReducer: promotionBannersReducer,
  questionsReducer: questionsReducer,
  entityAndAttributesReducer: entityAndAttributesReducer,
  plansReducer: plansReducer,
  mealsReducer: mealsReducer,
  foodTypesReducer: foodTypesReducer,
  calorieCalculationsReducer: calorieCalculationsReducer,
  workoutsReducer: workoutsReducer,
  medicalInstructionsReducer: medicalInstructionsReducer,
  contentOfTheDaysReducer: contentOfTheDaysReducer,
  dailyTasksReducer: dailyTasksReducer,
  milestonesReducer: milestonesReducer,
  ramazanContentOfTheDaysReducer: ramazanContentOfTheDaysReducer,
  ramazanTasksReducer: ramazanTasksReducer,
  ramazanMilestonesReducer: ramazanMilestonesReducer,
  chatGroupReducer: chatGroupReducer,
  sectionReducer: sectionReducer,
  personalTrainerReducer: personalTrainerReducer,
  packageTrainerPriceReducer: packageTrainerPriceReducer,
  trainingExpertiseReducer: trainingExpertiseReducer,
  bookedSessionReducer: bookedSessionReducer,
  notificationReducer: notificationReducer,
  faqsReducer: faqsReducer,
});

export default rootReducer;
