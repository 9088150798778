import * as t from "../types";

const defaultState = {
  faq: [],
  faqs: [],
  faqs_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,
  },
};

const faqsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_FAQ:
      return {
        ...state,
        faq: action.payload,
      };
    case t.SET_FAQS:
      return {
        ...state,
        faqs: action.payload,
      };
    case t.SET_FAQS_PAGINATION:
      return {
        ...state,
        faqs_pagination: action.payload,
      };
    case t.RESET_FAQS:
      return {
        ...state,
        faqs: [],
        faqs_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default faqsReducer;
