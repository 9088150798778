import React, {useState, useEffect} from "react";
import { EditorState, ContentState, convertFromHTML, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Editor } from 'react-draft-wysiwyg';
import { 
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";

function TextAreaEditor (props) {
    const [textBox, settextBox] = useState();
    const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.value?props.value:'<p></p>')
        )
      )
    );

    useEffect(()=>{
      // if(props.value) {
        let contentBlock = htmlToDraft(props.value?props.value:'<p></p>');
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks)
          )
        )
      // }
      settextBox(props.value?props.value:'<p></p>')
    } , [props.value])

    const updateTextDescription = async (state) => {
      await setEditorState(state);
      let data = convertToRaw(editorState.getCurrentContent());
      let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      html = "<div style='color: rgb(255,255,255);'>"+html+"</div>"; 
      settextBox(html);
      // console.log(html);
      // settextBox(editorState.getCurrentContent().getPlainText());
    };

    return (
      <FormGroup className="col-lg-12 mt-3 mb-0">
          <input type="hidden" name={props.name ?? 'description'} id={props.name ?? 'description'} value={textBox} />
          <Label for={props.name ?? 'description'}>{props.label ?? 'Description'}</Label>
          <Editor 
            editorState={editorState}
            onEditorStateChange={updateTextDescription}
            editorClassName="editorClassName"
          />
          { props.error && <FormFeedback>{props.error[props.name ?? 'description']}</FormFeedback> }
      </FormGroup>
    )
}
export default TextAreaEditor