import * as t from "../types";

const defaultState = {
  training_expertise: [],
  training_expertises: [],
  training_expertises_list: [],
  training_expertises_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,},
};

const trainingExpertiseReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_TRAINING_EXPERTISE:
     
      return {
        ...state,
        training_expertise: action.payload,
      };
    case t.SET_TRAINING_EXPERTISES:
      return {
        ...state,
        training_expertises: action.payload,
      };
    case t.SET_ALL_TRAINING_EXPERTISES:
        return {
            ...state,
            training_expertises_list: action.payload,
        };
    case t.SET_TRAINING_EXPERTISES_PAGINATION:
      return {
        ...state,
        training_expertises_pagination: action.payload,
      };
    case t.RESET_TRAINING_EXPERTISES:
      return {
        ...state,
        training_expertise: [],
        training_expertises: [],
        training_expertises_list: [],
        training_expertises_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default trainingExpertiseReducer;
