import React, { useState, useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    Table,
    Badge,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { PersonalTrainerReviewsListAction } from "../../redux/actions/PersonalTrainerAction";
import { connect } from "react-redux"
import Pagination from "../../components/Pagination/Pagination";

function PeronsalTrainerReviews (props) {
    const { trainerId } = useParams();
    const [currentPageNumber, setcurrentPageNumber] = useState(1);
  
    useEffect(()=>{
      if(currentPageNumber) props.PersonalTrainerReviewsListAction(trainerId,currentPageNumber).then(response => {});
    },[currentPageNumber]);

    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-plain tpf-card">
                <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">
                          Reviews & Ratings
                          <Badge color="info" pill>Total Reviews: {props?.personal_trainer_reviews?.rating?.total_reviews}</Badge>
                          <Badge color="info" pill>Overall Rating: {props?.personal_trainer_reviews?.rating?.overall_rating}</Badge>
                        </CardTitle>
                        {/* <p className="category">Sort by:</p> */}
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Table responsive className="tablesorter tpf-table">
                      <thead className="text-primary">
                        <tr>
                          <th>Customer</th>
                          <th>Customer Email</th>
                          <th>Rating</th>
                          <th>Review</th>
                          <th>Training Session</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props?.personal_trainer_reviews.records?.map((review) => (
                          <tr key={review.id}>
                            <td>{review?.customer?.fullName}</td>
                            <td>{review?.customer?.email}</td>
                            <td>{review?.rating}</td>
                            <td>{review?.review}</td>
                            <td>{review?.trainingSession?.package?.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination 
                      current_page={props?.personal_trainer_reviews_pagination?.currentPage}
                      limit={props?.personal_trainer_reviews_pagination?.limit}
                      previous={props?.personal_trainer_reviews_pagination?.previousPage}
                      next={props?.personal_trainer_reviews_pagination?.nextPage}
                      total_record={props?.personal_trainer_reviews_pagination?.totalCount}
                      total_pages={props?.personal_trainer_reviews_pagination?.totalPages}
                      setcurrentPageNumber={setcurrentPageNumber}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

const mapStateToProps = state => ({
  personal_trainer_reviews: state.personalTrainerReducer.personal_trainer_reviews,
  personal_trainer_reviews_pagination: state.personalTrainerReducer.personal_trainer_reviews_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  PersonalTrainerReviewsListAction: (trainerId, data, limit) => dispatch( PersonalTrainerReviewsListAction(trainerId,data, limit) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeronsalTrainerReviews)