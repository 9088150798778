import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    FormGroup,
    Label,
    Badge,
} from "reactstrap";
import { getHomeCategoriesListAction, homeCategoriesDeleteAction } from "../../redux/actions/homeCategoriesAction";
import { connect } from "react-redux"
import Pagination from "../../components/Pagination/Pagination";
// import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import anime3 from '../../assets/img/anime3.png';
import EditIcon from "../../components/Icons/EditIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";

function HomeCategories (props) {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const initialPage = parseInt(urlParams.get("page"), 10) ? parseInt(urlParams.get("page"), 10) : 1;
    const initialSearch = urlParams.get("searchBy") ?? ""
    const [currentPageNumber, setcurrentPageNumber] = useState(initialPage);
    const [searchBy, setSearchBy] = useState(initialSearch);

    useEffect(()=>{
      if(currentPageNumber) props.getHomeCategoriesListAction(currentPageNumber,"",searchBy).then(response => {});
    },[currentPageNumber,searchBy]);
    
    const deleteClickHandler = (id) => {
      props.homeCategoriesDeleteAction(id).then(response => {
        if(response) props.getHomeCategoriesListAction(currentPageNumber);
      });
    }
    return (
        <>
            <Row>
              <Col md="12">
                <Card className="card-plain tpf-card">
                  <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">
                          Home Categories
                          <Badge color="info" pill>{props?.questions_pagination?.totalCount}</Badge>
                        </CardTitle>
                        <div className="selectDown">
                          <FormGroup>
                            <Label for="sortBy">Recent</Label>
                          </FormGroup>
                        </div>
                      </Col>
                      <Col>
                        <div className="filter">
                          <SearchFieldNav setSearchBy={setSearchBy} />

                          <div className="addNew">
                            <Link to="/admin/homeCategories/create">
                              <Button color="primary">
                                Create new Category
                                <i className="fa fa-plus"/>{" "}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>
                        {props.home_categories.map((home_categories) => (
                          <Col lg="6" key={home_categories.id}>
                              <div className="tpf-horizontal-card p-3 with-bg my-2">
                                  <Row>
                                      <Col md="2">
                                          <figure className="figImage">
                                              <img src={home_categories.image_url ? home_categories.image_url : anime3} alt="..."/>
                                          </figure>
                                      </Col>

                                      <Col lg="8" className="d-flex align-items-center pl-0">
                                          <div className="title">
                                              <h5 className="card-title font-weight-bold">{home_categories.title}</h5>
                                              {/* <p>{home_categories.description}</p> */}
                                          </div>
                                      </Col>

                                      <Col lg="2" className="d-flex justify-content-end">
                                          <Button className="btn-icon" color="primary" size="sm" onClick={()=>{
                                              navigate('/admin/homeCategories/edit/'+home_categories.id, { replace: true, state: home_categories })
                                            }}>
                                              <EditIcon />
                                          </Button>
                                          <Button className="btn-icon" color="warning" size="sm" onClick={()=>deleteClickHandler(home_categories.id)}>
                                              <DeleteIcon />
                                          </Button>
                                      </Col>
                                  </Row>
                              </div>
                          </Col>
                        ))}
                    </Row>
                      
                    <Pagination 
                      current_page={props.home_categories_pagination.currentPage}
                      limit={props.home_categories_pagination.limit}
                      previous={props.home_categories_pagination.previousPage}
                      next={props.home_categories_pagination.nextPage}
                      total_record={props.home_categories_pagination.totalCount}
                      total_pages={props.home_categories_pagination.totalPages}
                      setcurrentPageNumber={setcurrentPageNumber}
                      search={searchBy}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </>
    )
}

const mapStateToProps = state => ({
  home_categories: state.homeCategoriesReducer.home_categories,
  home_categories_pagination: state.homeCategoriesReducer.home_categories_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  getHomeCategoriesListAction: (data, limit, searchBy) => dispatch( getHomeCategoriesListAction(data, limit, searchBy) ),
  homeCategoriesDeleteAction: (data, navigate) => dispatch( homeCategoriesDeleteAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeCategories)