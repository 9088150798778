const APP_CONSTANTS = {
  DEFAULT_PAGE: 1,
  DEFAULT_LIMIT_PER_PAGE: 20,
  DEFAULT_LIMIT_WHEN_IN_SELECT: "500",
  PUSHER_KEY: "ccd0687bcd3d3c04bc44",
  PUSHER_CLUSTER: "ap1",
  CHAT_CHANNEL: "CHAT",
  NEW_CHAT_EVENT: "NEW-CHAT",
  CHAT_EVENT_ACCEPT: "CHAT-ACCEPTED",
  CHAT_EVENT_CLOSED: "CHAT-ACCEPTED",
  NEW_MESSAGE: "NEW-MESSAGE",
  RAMAZAN_CONTENT_TYPE: {
    weight_gain: "Weight Gain",
    weight_loss: "Weight Loss",
  },
  DAILY_TASK_TYPE: {
    both: "Both",
    weight_gain: "Weight Gain",
    weight_loss: "Weight Loss",
    muscle_gain: "Muscle Gain",
    medical_condition: "Medical Condition",
  },
  CATEGORIES_TYPE: {
    food_calorie: "Food Calorie",
    home_page: "Home Page",
    workout: "Workout",
    meal_plan: "Meal Plan",
  },
  PLAN_TYPES_CONSTANT_VALUE: "PACKAGE_TYPE",
  BOOKED_SESSION_DEFAULT_STATUS: "pending",
  MILESTONE_WEIGHT_CATEGORIZATION: {
    both: "Weight Maintenance",
    weight_gain: "Weight Gain",
    weight_loss: "Weight Loss",
    muscle_gain: "Muscle Gain",
  },
  MILESTONE_MEDICAL_CONDITION_CATEGORIZATION: {
    diabetes: "Diabetes",
    hyper_tension: "Hypertension (High Blood Pressure)",
    // hypotension: "Hypotension",
    pcos: "PCOS/PCOD",
    cholecystectomy: "Cholecystectomy",
    hypoglycemia: "Hypoglycemia",
    fatty_liver: "Fatty Liver",
    heart_disease: "Heart disease",
    asthma: "Asthma",
    thyroid_disorder: "Thyroid disorder (hypo and hyper)",
    migraine: "Migraine",
    // gastrointestinal_disorder:
    //   "Gastrointestinal disorder (e.g, constipation, diarrhea, Celiac Disease",
    lactose_intolerance: "Lactose Intolerance",
    iron_deficiency: "Iron deficiency anemia",
    gout: "Gout (high uric acid)",
    arthritis: "Arthritis",
    ibs: "IBS",
    celiac_disease: "Celiac Disease",
  },
  MILESTONE_DIET_CATEGORIZATION: {
    vegan: "Vegan",
    balanced: "Balanced diet",
    ovo_vegetaraian: "Ovo-vegetaraian",
  },
  MILESTONE_PHYSICAL_ACTIVITY_CATEGORIZATION: {
    sedentary: "Sedentary",
    moderate: "Moderate",
    intense: "Intense",
    athlete: "Athlete",
  },
  BANNER_IMAGE_DIRECTORY: "banner",
  QUESTION_IMAGE_DIRECTORY: "question",
  MEAL_PLAN_IMAGE_DIRECTORY: "meal_plans",
  DAY_CONTENT_VIDEO_DIRECTORY: "videos",
  WORKOUT_IMAGE_DIRECTORY: "workout",
  WORKOUT_VIDEO_DIRECTORY: "workout-videos",
  DAILY_TASK_IMAGE_DIRECTORY: "daily_tasks",
  DAY_CONTENT_IMAGE_DIRECTORY: "content",
  CUSTOMER_IMAGE_DIRECTORY: "customer",
  NOTIFICATION_DEFAULT_STATUS: "pending",
};
export default APP_CONSTANTS;
