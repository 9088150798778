import axios from 'axios';
import { userLogoutAction } from "../redux/actions/userAction";
import { useNavigate } from "react-router-dom";
const instance = axios.create();

const interceptor = instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const navigate = useNavigate
      userLogoutAction().then(response => {
          navigate('/');
      });
    }
    return Promise.reject(error);
  }
);

export default instance;

// Cleanup the interceptor on component unmount
export const removeInterceptor = () => {
  instance.interceptors.response.eject(interceptor);
};