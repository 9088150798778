import React, {useState, useEffect} from "react";
import "../../assets/scss/login.scss";
import { useNavigate } from "react-router-dom";
import { userLoginAction } from "../../redux/actions/userAction";
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  CardSubtitle,
  FormFeedback,
} from "reactstrap";

function Login(props) {
  const navigate = useNavigate()

  const [error, setError] = useState({});
  const [auth, setAuth] = useState({
    email: "",
    password: ""
  });
  const [show_password, set_show_password] = useState(false);

  useEffect(()=>{
    if(props.errors) setError(props.errors);
  } , [props.errors])

  const handleChange = (e) => {
    setAuth((currentState) => ({
      ...currentState,
      [e.target.name]: e.target.value
    }));
    setError({})
  }
  
  const doLogin = (e) => {
    e.preventDefault();
    props.userLoginActionTemp(auth, navigate).then(response => {});
  }

	return (
		<>
			<div className="login-wrapper">
          <Row>
              <Col md="4">
                  <div className="login-panel">
                      <Card className="Brand">
                          <CardBody>
                                <figure>
                                    <svg width="232" height="129" viewBox="0 0 232 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M106.737 45.0368C107.343 45.0368 107.975 45.1806 108.623 44.7661C110.484 39.589 112.362 34.3442 114.24 29.0994C114.29 28.9556 114.248 28.7779 114.248 28.5072C113.608 28.4988 112.994 28.4734 112.387 28.4734C108.623 28.4649 104.867 28.4734 101.103 28.4649C100.682 28.4649 100.261 28.3888 99.7136 28.338C101.044 24.4806 102.425 20.8092 103.781 17.1632C104.53 16.901 105.213 16.9856 105.878 16.9856C114.349 16.9856 122.829 16.9856 131.301 16.9856C131.907 16.9856 132.522 16.9517 133.179 16.9348C135.09 11.6139 136.977 6.36909 138.855 1.12429C138.905 0.98048 138.863 0.802833 138.863 0.616727C138.703 0.557512 138.552 0.464459 138.4 0.464459C109.617 0.44754 80.8167 0.44754 52.025 0.44754C51.8735 0.44754 51.7303 0.532134 51.3429 0.642106C52.2945 2.07174 53.2377 3.36602 54.1556 4.68568C55.124 6.06455 56.1008 7.45189 57.0693 8.83076C58.004 10.1673 58.9387 11.5124 59.8903 12.832C60.8756 14.194 61.7514 15.6405 62.8882 16.9771C63.5703 16.9771 64.2356 16.9771 64.9093 16.9771C70.9724 16.9856 77.0356 16.9856 83.0988 16.994C83.6293 16.994 84.1935 16.8925 84.783 17.2055C83.4946 21.0292 82.0714 24.6921 80.7325 28.3888C79.3851 32.094 78.0293 35.7907 76.6735 39.4959C75.3009 43.2519 73.9198 47.0078 72.5472 50.7553C71.1998 54.4436 69.8019 58.115 68.463 61.9725C70.1219 62.2347 85.7935 62.2347 87.3009 61.9894C87.4777 61.541 87.6883 61.025 87.8735 60.509C89.583 55.8563 91.2756 51.2037 92.9935 46.551C93.1788 46.0435 93.2378 45.4344 93.8693 45.0368C94.3914 45.0368 95.0062 45.0368 95.6209 45.0368C99.3262 45.0453 103.031 45.0537 106.737 45.0368Z" fill="white"/>
                                    <path d="M143.764 16.9771C144.775 16.9771 145.785 16.9771 146.796 16.9771C147.806 16.9771 148.817 16.9771 149.827 16.9771C150.838 16.9771 151.848 16.9687 152.859 16.994C153.844 17.0194 154.846 16.8925 155.916 17.1378C155.815 18.2629 155.301 19.1512 154.989 20.0986C154.686 21.0038 154.341 21.9005 154.013 22.7972C153.659 23.7446 153.331 24.709 152.952 25.648C152.573 26.57 152.396 27.5852 151.731 28.4565C151.107 28.4565 150.493 28.4565 149.878 28.4565C141.179 28.4565 132.48 28.4565 123.773 28.4649C123.116 28.4649 122.434 28.3719 121.684 28.651C117.701 39.5128 113.709 50.4 109.726 61.2872C109.65 61.4818 109.642 61.7102 109.583 62.0147C109.92 62.074 110.181 62.1501 110.442 62.1586C115.773 62.3616 126.93 62.2431 128.387 61.9471C129.389 59.2232 130.408 56.4316 131.444 53.64C132.497 50.7976 133.474 47.9215 134.644 45.0368C135.385 45.0368 136.05 45.0368 136.716 45.0368C145.415 45.0368 154.114 45.0368 162.821 45.0284C163.478 45.0284 164.168 45.1722 164.834 44.8084C165.053 44.2501 165.288 43.6749 165.499 43.0912C168.051 36.1122 170.602 29.1248 173.162 22.1458C175.638 15.3783 178.122 8.61082 180.598 1.83487C180.75 1.42882 181.036 1.03123 180.808 0.56597C179.133 0.31219 148.084 0.329108 146.434 0.591348C146.324 0.802832 146.181 1.03969 146.08 1.29347C144.295 6.21682 142.526 11.1486 140.758 16.072C140.674 16.3088 140.691 16.588 140.64 16.9687C141.752 16.9771 142.754 16.9771 143.764 16.9771Z" fill="white"/>
                                    <path d="M157.836 96.5881C157.558 94.2026 156.446 92.3246 154.375 91.0895C151.36 89.2792 147.301 89.6345 144.615 91.9101C144.278 92.1977 143.941 92.4853 143.579 92.7899C140.034 89.2539 136.194 89.1354 132.05 91.7494C131.933 91.2587 131.84 90.8611 131.739 90.4297C129.962 90.379 128.303 90.2436 126.585 90.4974C126.543 91.0134 126.476 91.4533 126.476 91.8932C126.467 97.6963 126.467 103.499 126.467 109.303C126.467 109.624 126.552 109.937 126.61 110.352C127.528 110.36 128.362 110.402 129.196 110.368C130.063 110.335 130.964 110.588 131.907 110.148C131.907 108.99 131.907 107.873 131.907 106.748C131.916 103.99 131.874 101.224 131.949 98.4661C132.008 96.3344 133.179 95.1416 135.242 94.9047C137.541 94.6425 139.301 96.1144 139.436 98.4492C139.469 98.9567 139.453 99.4643 139.453 99.9719C139.461 102.848 139.453 105.716 139.469 108.592C139.469 109.142 139.352 109.726 139.697 110.233C141.095 110.436 143.486 110.445 144.707 110.242C145.011 109.666 144.909 109.032 144.918 108.423C144.926 105.775 144.901 103.127 144.918 100.479C144.926 99.5235 145.019 98.5676 145.095 97.6117C145.162 96.6981 145.676 96.0467 146.4 95.5476C147.023 95.1247 147.739 94.9555 148.463 94.8963C150.619 94.7271 152.295 96.1652 152.463 98.3138C152.505 98.8722 152.497 99.4389 152.497 100.006C152.497 102.992 152.497 105.978 152.505 108.964C152.505 109.396 152.581 109.827 152.632 110.36C154.392 110.385 156.051 110.495 157.802 110.301C157.836 109.793 157.895 109.353 157.895 108.922C157.903 105.259 157.895 101.596 157.886 97.9332C157.886 97.4933 157.886 97.0365 157.836 96.5881Z" fill="white"/>
                                    <path d="M90.4419 82.3087C88.9682 81.5559 87.3767 81.2344 85.7261 81.2259C82.2482 81.2175 78.7703 81.2175 75.2924 81.2259C74.9724 81.2259 74.6524 81.3021 74.2314 81.3528C74.1977 81.9788 74.1387 82.5287 74.1387 83.0786C74.1303 85.7263 74.1219 88.3741 74.1219 91.0219C74.1219 96.7743 74.1219 102.518 74.1472 108.271C74.1472 108.93 73.9956 109.624 74.4335 110.36C75.2756 110.368 76.1682 110.402 77.0524 110.377C77.9198 110.352 78.8209 110.605 79.7472 110.14C79.7472 106.68 79.7472 103.254 79.7472 99.6166C80.5135 99.5828 81.0609 99.532 81.6167 99.532C83.1914 99.5151 84.7577 99.5574 86.3325 99.5066C92.0419 99.3121 95.7977 95.0316 95.2672 89.33C94.9725 86.0985 93.3135 83.7722 90.4419 82.3087ZM86.3577 94.7948C85.8693 94.9047 85.364 94.9809 84.8672 94.9893C83.4693 95.0147 82.0714 95.0062 80.6735 94.9893C80.4124 94.9893 80.143 94.8794 79.9072 94.8286C79.6545 93.1029 79.6966 87.3167 79.9661 85.9801C80.3198 85.9463 80.6988 85.8786 81.0777 85.8701C82.3072 85.8532 83.5367 85.8617 84.7661 85.8701C85.1535 85.8701 85.5493 85.8871 85.9367 85.9463C88.1346 86.2508 89.6251 87.9173 89.7346 90.2013C89.844 92.4769 88.5051 94.3041 86.3577 94.7948Z" fill="white"/>
                                    <path d="M46.8208 108.939C46.8292 105.276 46.8208 101.613 46.8124 97.9416C46.8124 97.4933 46.7955 97.0365 46.7366 96.5882C46.3745 93.712 44.884 91.631 42.2145 90.5651C39.6208 89.5331 37.0524 89.7192 34.6271 91.208C34.3071 91.4026 34.0039 91.6141 33.5324 91.927C33.4818 91.3603 33.4229 90.9965 33.4229 90.6243C33.4229 87.7481 33.4313 84.8719 33.4229 81.9958C33.4229 81.5136 33.5576 80.9806 33.1534 80.5746C31.2755 80.3208 29.3639 80.3462 28.1429 80.6592C27.8566 81.2344 27.9745 81.8689 27.9745 82.4779C27.966 90.9881 27.9745 99.5066 27.9745 108.017C27.9745 108.465 27.9576 108.922 27.9913 109.37C28.0166 109.683 28.126 109.988 28.2271 110.402C29.9534 110.275 31.6124 110.563 33.3134 110.242C33.3555 109.709 33.4229 109.269 33.4229 108.829C33.4313 106.232 33.4145 103.643 33.4313 101.046C33.4397 100.15 33.465 99.2444 33.5576 98.3477C33.7682 96.2921 34.9808 95.1078 37.0355 94.9047C39.284 94.6848 41.1282 96.1821 41.3471 98.4408C41.4061 99.0583 41.3976 99.6758 41.3976 100.302C41.4061 103.178 41.3976 106.054 41.4061 108.93C41.4061 109.37 41.4818 109.802 41.524 110.208C43.0145 110.453 45.3303 110.453 46.7198 110.208C46.7619 109.802 46.8208 109.37 46.8208 108.939Z" fill="white"/>
                                    <path d="M203.924 81.2429C197.979 81.2344 192.025 81.2344 186.08 81.2429C185.827 81.2429 185.566 81.3697 185.229 81.4543C185.229 90.8781 185.229 100.234 185.238 109.59C185.238 109.802 185.322 110.013 185.373 110.258C185.634 110.309 185.844 110.385 186.055 110.385C187.562 110.394 189.078 110.394 190.509 110.394C190.998 109.886 190.846 109.353 190.855 108.863C190.872 105.876 190.855 102.882 190.863 99.8957C190.863 99.4643 190.947 99.0329 190.989 98.5338C191.638 98.5084 192.194 98.4746 192.741 98.4746C196 98.4661 199.251 98.4746 202.51 98.4661C202.939 98.4661 203.377 98.4069 203.891 98.373C204.034 96.7235 204.059 95.1839 203.891 93.5258C203.259 93.492 202.712 93.4413 202.164 93.4328C198.855 93.4243 195.537 93.4243 192.227 93.4159C191.798 93.4159 191.318 93.5428 190.863 93.0437C190.872 90.9119 190.779 88.6786 190.931 86.3354C191.638 86.3016 192.244 86.2423 192.842 86.2423C196.27 86.2339 199.688 86.2508 203.116 86.2423C203.604 86.2423 204.126 86.3946 204.556 86.0055C204.8 83.916 204.75 82.4187 204.404 81.3444C204.253 81.3021 204.084 81.2429 203.924 81.2429Z" fill="white"/>
                                    <path d="M57.8103 90.2267C53.5661 91.1403 50.324 94.9978 49.945 99.2697C49.5324 103.948 51.9408 108.203 56.2103 109.979C57.5745 110.546 59.0145 110.8 60.4714 110.825C63.3093 110.876 65.903 110.132 68.0588 108.177C68.2945 107.966 68.463 107.678 68.7324 107.34C67.7051 106.299 66.7282 105.318 65.7261 104.312C65.4566 104.498 65.2714 104.616 65.0945 104.751C64.9177 104.887 64.7493 105.039 64.564 105.157C62.6356 106.376 60.5893 106.587 58.4419 105.868C57.6251 105.597 56.9429 105.098 56.4208 104.413C55.9829 103.846 55.5619 103.263 55.5366 102.34C56.7829 102.281 57.945 102.281 59.1072 102.273C60.2861 102.264 61.4651 102.264 62.6356 102.264C63.8651 102.264 65.103 102.264 66.3324 102.256C67.503 102.247 68.6651 102.231 69.8693 102.214C69.9872 101.791 70.1135 101.478 70.1472 101.156C70.4503 98.1024 69.7682 95.3192 67.663 93.0267C64.9598 90.0829 61.5493 89.4231 57.8103 90.2267ZM64.6903 98.0939C64.6482 98.1277 64.5893 98.1531 64.5387 98.1531C61.9703 98.1785 59.3935 98.2123 56.825 98.2292C56.4377 98.2292 56.0503 98.1869 55.5366 98.1616C56.244 95.6491 58.0798 94.3549 60.5808 94.4394C62.8966 94.524 64.303 95.7084 64.783 97.967C64.7493 98.0093 64.724 98.0601 64.6903 98.0939Z" fill="white"/>
                                    <path d="M176.699 104.345C176.446 104.531 176.261 104.658 176.093 104.794C174.577 106.071 172.792 106.435 170.888 106.215C168.968 105.986 167.537 104.997 166.745 103.178C166.653 102.958 166.703 102.662 166.686 102.307C168.362 102.29 169.928 102.273 171.495 102.264C173.069 102.256 174.636 102.273 176.211 102.256C177.768 102.239 179.335 102.315 180.952 102.18C181.76 99.6927 180.977 95.9621 179.486 93.915C177.179 90.7596 173.423 89.2116 168.851 90.2182C165.255 91.0049 161.625 94.2195 161.044 98.9737C160.404 104.168 163.318 108.744 168.126 110.284C170.206 110.944 172.337 111.003 174.451 110.555C176.522 110.115 178.349 109.184 179.815 107.391C178.796 106.333 177.869 105.242 176.699 104.345ZM168.152 95.5392C169.92 94.2364 171.848 94.0334 173.836 95.0062C175.048 95.5984 175.621 96.5204 175.84 98.0516C174.206 98.3054 167.966 98.2885 166.611 98.0178C166.754 96.9519 167.335 96.1398 168.152 95.5392Z" fill="white"/>
                                    <path d="M24.4882 81.319C24.2018 81.2851 23.9239 81.2344 23.6545 81.2344C16.3029 81.2344 8.9597 81.2344 1.60811 81.2428C1.28811 81.2428 0.968108 81.3359 0.597581 81.3951C0.496528 83.0193 0.395475 84.5674 0.656528 86.2254C1.73443 86.2423 2.73653 86.2592 3.73022 86.2677C4.6818 86.2762 5.6418 86.2592 6.59338 86.2762C7.58707 86.3015 8.58918 86.1746 9.69234 86.403C9.7176 87.0798 9.7597 87.6889 9.7597 88.2895C9.76813 94.9978 9.75971 101.706 9.77655 108.406C9.77655 109.006 9.63339 109.641 9.96181 110.208C11.4944 110.478 14.1302 110.461 15.4187 110.14C15.4187 106.249 15.4187 102.315 15.4187 98.3815C15.4187 94.4394 15.4187 90.5058 15.4187 86.3438C16.1766 86.31 16.7239 86.2677 17.2713 86.2677C19.3429 86.2592 21.4229 86.2762 23.4945 86.2592C23.865 86.2592 24.2271 86.1577 24.6987 86.09C24.7071 85.2103 24.7576 84.432 24.7155 83.6538C24.6818 82.8924 24.9176 82.0888 24.4882 81.319Z" fill="white"/>
                                    <path d="M231.655 90.5059C230.156 90.1336 228.674 90.6158 227.175 90.1167C227.166 89.1947 227.166 88.298 227.158 87.4013C227.158 86.5553 227.175 85.7094 227.15 84.8635C227.124 83.9752 227.268 83.0785 226.964 82.0296C225.272 82.0296 223.621 81.8942 221.996 82.1057C221.617 82.6133 221.718 83.1378 221.71 83.6284C221.693 85.4302 221.693 87.2321 221.667 89.0424C221.659 89.4738 221.743 89.956 221.314 90.269C219.95 90.5566 218.526 90.1167 217.112 90.5651C217.036 92.0539 216.985 93.492 217.154 95.0147C218.661 95.2685 220.126 94.9724 221.491 95.2262C221.844 95.9114 221.71 96.5458 221.71 97.1464C221.718 100.869 221.71 104.591 221.71 108.304C221.71 108.913 221.743 109.522 221.769 110.216C223.823 110.461 225.87 110.436 227.082 110.148C227.099 109.573 227.141 108.964 227.141 108.355C227.15 104.523 227.141 100.691 227.15 96.8588C227.15 96.3174 227.006 95.7337 227.436 95.1585C228.076 95.1416 228.741 95.1331 229.406 95.1077C230.122 95.0824 230.855 95.2008 231.63 94.9978C231.849 93.4666 231.916 91.9862 231.655 90.5059Z" fill="white"/>
                                    <path d="M110.829 90.0575C108.775 89.7191 107.495 90.0575 104.598 91.6394C104.471 91.2249 104.345 90.8273 104.21 90.4213C102.518 90.4213 100.901 90.4213 99.1493 90.4213C99.0904 90.8189 99.023 91.0388 99.023 91.2503C99.023 97.3326 99.023 103.415 99.0314 109.497C99.0314 109.759 99.1493 110.013 99.242 110.36C100.951 110.335 102.61 110.495 104.345 110.267C104.379 109.632 104.429 109.074 104.429 108.524C104.438 106.105 104.404 103.677 104.438 101.258C104.455 100.133 104.539 99.0075 104.665 97.8909C104.834 96.3767 105.743 95.4715 107.175 95.057C108.185 94.7609 109.17 94.8371 110.122 95.2939C110.408 95.4292 110.686 95.5899 111.006 95.7676C111.596 95.0993 112.135 94.5241 112.623 93.915C113.145 93.2721 113.844 92.7561 114.139 91.9101C113.271 90.8189 112.126 90.269 110.829 90.0575Z" fill="white"/>
                                    <path d="M213.642 90.3959C212.042 90.4128 210.375 90.2521 208.733 90.472C208.421 91.9101 208.329 100.378 208.514 108.947C208.522 109.387 208.598 109.819 208.657 110.352C210.425 110.402 212.093 110.385 213.929 110.394C213.962 109.751 214.004 109.362 214.013 108.981C214.021 107.856 214.021 106.722 214.021 105.597C214.021 101.089 214.03 96.5797 214.013 92.0624C214.013 91.521 214.19 90.9288 213.642 90.3959Z" fill="white"/>
                                    <path d="M116.295 90.6074C116.269 90.8442 116.21 91.1149 116.21 91.3856C116.21 97.3664 116.21 103.339 116.219 109.319C116.219 109.641 116.328 109.954 116.404 110.352C117.861 110.368 119.259 110.394 120.657 110.394C120.935 110.394 121.204 110.326 121.507 110.292C121.575 110.047 121.676 109.835 121.676 109.616C121.684 103.415 121.684 97.2141 121.676 91.0134C121.676 90.8611 121.566 90.7004 121.516 90.5566C120.025 90.2859 117.617 90.2944 116.295 90.6074Z" fill="white"/>
                                    <path d="M211.175 80.896C209.516 80.9214 208.101 82.3933 208.202 84.0937C208.32 86.1662 209.785 87.1306 211.352 87.0798C213.39 87.0798 214.358 85.3795 214.333 83.9245C214.299 82.2157 212.867 80.8706 211.175 80.896Z" fill="white"/>
                                    <path d="M118.872 80.896C117.246 80.9214 115.89 82.2665 115.89 83.9499C115.89 85.8109 117.137 87.0798 118.93 87.0798C120.876 87.1221 122.004 85.6164 122.013 83.9922C122.021 82.258 120.581 80.8707 118.872 80.896Z" fill="white"/>
                                    <path d="M43.3177 123.802C43.3345 122.448 43.3177 121.095 43.3008 119.741C43.2924 119.268 43.1998 119.234 42.484 119.234C42.1893 120.74 42.4924 122.313 42.1977 123.996C41.4735 123.43 41.1366 122.778 40.6903 122.228C40.2692 121.704 39.8987 121.129 39.4608 120.621C39.0061 120.088 38.7871 119.302 37.8271 119.073C37.7429 119.369 37.6671 119.521 37.6671 119.674C37.6503 121.586 37.6419 123.506 37.6419 125.418C37.6419 125.638 37.7092 125.858 37.785 126.061C37.8103 126.137 37.9619 126.221 38.0461 126.204C38.1977 126.187 38.4082 126.145 38.4587 126.035C38.5598 125.849 38.5935 125.612 38.6019 125.392C38.6187 124.318 38.6103 123.252 38.6187 122.178C38.6187 121.924 38.5261 121.602 38.9724 121.45C39.3345 121.915 39.7135 122.389 40.0756 122.88C40.825 123.87 41.5577 124.859 42.3071 125.849C42.5261 126.137 42.7956 126.323 43.2335 126.069C43.4187 125.358 43.3093 124.572 43.3177 123.802Z" fill="white"/>
                                    <path d="M167.091 121.763C166.509 121.273 165.794 121.12 165.036 121.332C164.025 121.611 163.377 122.245 163.2 123.294C163.015 124.377 163.276 125.308 164.227 125.951C165.112 126.543 166.341 126.517 167.225 125.883C167.309 125.824 167.419 125.705 167.411 125.621C167.394 125.477 167.284 125.223 167.234 125.223C166.905 125.248 166.585 125.325 166.265 125.409C165.625 125.587 165.027 125.528 164.547 125.054C164.387 124.893 164.354 124.589 164.244 124.301C165.499 123.988 166.695 124.36 167.848 124.022C168.109 123.083 167.764 122.338 167.091 121.763ZM164.152 123.244C164.531 122.347 165.069 122.135 165.718 122.144C166.333 122.152 166.762 122.44 166.947 123.176C166.063 123.599 165.196 123.396 164.152 123.244Z" fill="white"/>
                                    <path d="M123.293 121.408C122.206 121.002 120.977 121.416 120.354 122.406C119.663 123.497 119.874 124.969 120.825 125.781C121.709 126.526 123.225 126.56 124.084 125.832C124.177 125.748 124.236 125.502 124.177 125.401C124.118 125.291 123.89 125.198 123.764 125.223C123.377 125.291 123.015 125.46 122.627 125.502C121.819 125.595 121.381 125.24 121.112 124.318C121.693 124.064 122.316 124.242 122.905 124.183C123.503 124.123 124.135 124.326 124.69 123.988C124.724 122.499 124.337 121.805 123.293 121.408ZM121.095 123.26C121.465 122.338 121.726 122.144 122.476 122.135C123.166 122.127 123.587 122.448 123.832 123.32C122.804 123.438 121.962 123.506 121.095 123.26Z" fill="white"/>
                                    <path d="M193.204 121.433C192.093 121.002 190.905 121.374 190.257 122.364C189.592 123.379 189.718 124.792 190.543 125.629C191.385 126.484 192.935 126.61 193.937 125.883C194.046 125.807 194.139 125.578 194.105 125.46C194.072 125.35 193.853 125.198 193.743 125.215C193.415 125.257 193.103 125.384 192.775 125.46C192.194 125.595 191.638 125.494 191.225 125.062C191.065 124.893 191.032 124.597 190.922 124.309C192.177 123.996 193.373 124.377 194.493 124.039C194.627 122.592 194.249 121.839 193.204 121.433ZM190.973 123.294C190.99 123.091 190.947 122.897 191.015 122.787C191.276 122.398 191.663 122.195 192.126 122.135C192.876 122.051 193.179 122.262 193.592 123.303C192.64 123.438 191.832 123.472 190.973 123.294Z" fill="white"/>
                                    <path d="M76.3114 121.239C74.8798 121.281 73.8104 122.431 73.8525 123.903C73.8946 125.367 75.0146 126.416 76.4883 126.365C77.903 126.323 79.0483 125.113 78.9977 123.726C78.9472 122.355 77.6925 121.205 76.3114 121.239ZM76.4798 125.511C75.5872 125.587 74.7872 124.817 74.7535 123.844C74.7198 122.964 75.4019 122.212 76.2777 122.161C77.2293 122.102 77.9872 122.821 78.004 123.802C78.0125 124.665 77.3304 125.435 76.4798 125.511Z" fill="white"/>
                                    <path d="M83.1662 121.34C82.4925 121.577 81.8862 121.349 81.1114 121.433C81.1451 123.041 80.9935 124.589 81.1872 126.094C81.6335 126.306 81.962 126.247 82.0041 125.807C82.0799 124.91 82.0462 124.013 82.1388 123.117C82.1978 122.491 82.6778 122.144 83.3009 122.135C83.9241 122.135 84.3957 122.474 84.5051 123.083C84.5893 123.573 84.5809 124.089 84.6146 124.589C84.6399 125.037 84.6399 125.485 84.7241 125.917C84.7493 126.052 85.002 126.154 85.3725 126.433C85.5914 125.045 85.5914 123.98 85.4651 122.922C85.3051 121.772 84.2525 120.96 83.1662 121.34Z" fill="white"/>
                                    <path d="M102.72 119.2C101.347 118.972 99.983 118.938 98.5178 119.183C98.4251 121.442 98.4083 123.624 98.543 125.798C98.5514 125.934 98.8041 126.094 98.9725 126.17C99.023 126.196 99.2335 125.993 99.2757 125.866C99.343 125.654 99.3346 125.426 99.3514 125.198C99.3851 124.589 99.402 123.98 99.4357 123.167C100.244 123.151 100.859 123.176 101.457 123.117C101.92 123.074 102.728 123.345 102.737 122.711C102.745 121.89 101.886 122.305 101.398 122.22C100.758 122.11 100.067 122.364 99.4357 121.958C99.3767 121.273 99.1914 120.613 99.7051 119.902C100.278 119.902 100.884 119.911 101.499 119.902C101.886 119.894 102.282 119.877 102.661 119.818C102.762 119.801 102.846 119.581 102.888 119.445C102.905 119.369 102.796 119.208 102.72 119.2Z" fill="white"/>
                                    <path d="M161.861 118.946C160.522 118.379 159.52 119.158 159.301 120.029C159.208 120.401 159.158 120.782 159.099 121.12C158.695 121.298 158.349 121.45 158.029 121.594C158.114 122.321 158.762 122.076 159.107 122.398C159.394 123.607 158.989 124.919 159.352 126.145C159.789 126.28 160.093 126.179 160.118 125.748C160.16 125.02 160.135 124.293 160.16 123.565C160.177 123.134 160.253 122.711 160.286 122.372C160.783 122.042 161.6 122.448 161.583 121.476C161.12 121.374 160.699 121.289 160.303 121.205C160.202 119.741 160.236 119.699 161.524 119.716C161.583 119.716 161.659 119.758 161.684 119.733C161.836 119.606 162.021 119.488 162.105 119.318C162.147 119.251 161.987 118.997 161.861 118.946Z" fill="white"/>
                                    <path d="M183.832 121.679C183.478 122.381 183.183 123.117 182.846 123.819C182.737 124.047 182.543 124.225 182.316 124.512C181.878 123.506 181.507 122.626 181.112 121.763C180.943 121.399 180.783 121.399 180.126 121.704C180.43 122.423 180.648 123.193 181.044 123.861C181.836 125.206 181.895 126.483 181.002 127.786C180.943 127.871 180.943 127.998 180.901 128.158C181.516 128.48 181.768 128.192 181.945 127.803C182.863 125.815 183.773 123.819 184.682 121.822C184.724 121.738 184.674 121.611 184.665 121.501C184.32 121.239 184 121.34 183.832 121.679Z" fill="white"/>
                                    <path d="M115.663 121.281C115.335 121.332 115.031 121.552 114.711 121.577C114.358 121.611 113.996 121.509 113.499 121.459C113.49 122.871 113.482 124.157 113.482 125.443C113.482 125.612 113.465 125.832 113.566 125.925C113.735 126.077 113.979 126.154 114.189 126.264C114.535 125.638 114.383 125.062 114.417 124.512C114.45 124.064 114.425 123.607 114.493 123.167C114.594 122.525 115.023 122.169 115.646 122.152C116.278 122.135 116.766 122.448 116.901 123.049C117.002 123.48 116.994 123.937 117.027 124.386C117.061 124.893 117.053 125.401 117.111 125.9C117.12 126.01 117.33 126.162 117.448 126.171C117.575 126.179 117.81 126.027 117.819 125.942C117.844 124.876 117.92 123.802 117.794 122.753C117.667 121.746 116.665 121.129 115.663 121.281Z" fill="white"/>
                                    <path d="M49.5745 121.459C49.4482 121.459 49.2292 121.611 49.2124 121.721C49.145 122.161 49.1366 122.609 49.1113 123.049C49.0861 123.548 49.1029 124.064 49.0187 124.555C48.9261 125.13 48.3619 125.519 47.7977 125.485C47.3092 125.46 46.8629 125.071 46.7871 124.529C46.7198 124.09 46.745 123.633 46.7198 123.184C46.6861 122.634 46.8292 122.059 46.5008 121.425C46.2819 121.535 45.8945 121.636 45.8861 121.746C45.8608 122.804 45.8019 123.87 45.945 124.91C46.0713 125.815 46.8208 126.297 47.7555 126.357C48.7745 126.416 49.5408 125.967 49.844 125.028C50.1977 123.937 50.0882 122.821 49.9366 121.712C49.9282 121.611 49.7008 121.459 49.5745 121.459Z" fill="white"/>
                                    <path d="M134.989 123.675C134.543 123.455 134.038 123.337 133.592 123.117C133.423 123.032 133.229 122.787 133.238 122.618C133.246 122.305 133.516 122.118 133.819 122.152C134.257 122.203 134.678 122.355 135.107 122.415C135.234 122.431 135.444 122.313 135.503 122.195C135.562 122.085 135.52 121.831 135.427 121.763C134.77 121.247 134.029 121.07 133.229 121.374C132.657 121.586 132.328 122.017 132.269 122.634C132.219 123.218 132.48 123.624 133.128 123.929C133.381 124.047 133.65 124.115 133.912 124.225C134.114 124.309 134.349 124.369 134.509 124.512C134.653 124.648 134.813 124.893 134.779 125.045C134.737 125.223 134.501 125.435 134.316 125.468C133.996 125.528 133.65 125.494 133.33 125.426C133.01 125.358 132.716 125.198 132.345 125.062C132.21 125.384 132.025 125.629 132.345 125.858C133.069 126.382 133.869 126.509 134.737 126.272C135.352 126.103 135.655 125.663 135.739 125.054C135.832 124.445 135.596 123.98 134.989 123.675Z" fill="white"/>
                                    <path d="M172.581 123.633C172.126 123.421 171.63 123.303 171.183 123.074C171.04 122.998 170.88 122.702 170.922 122.558C170.973 122.398 171.234 122.178 171.394 122.186C171.933 122.203 172.48 122.313 173.019 122.389C173.297 122.178 173.339 121.932 173.053 121.712C172.497 121.273 171.857 121.137 171.158 121.289C170.476 121.442 170.038 121.899 169.962 122.525C169.878 123.21 170.131 123.624 170.863 123.954C171.318 124.157 171.798 124.326 172.236 124.546C172.354 124.606 172.48 124.851 172.446 124.961C172.387 125.147 172.236 125.375 172.067 125.443C171.815 125.536 171.512 125.545 171.251 125.494C170.931 125.435 170.627 125.24 170.307 125.164C170.181 125.13 169.954 125.232 169.895 125.333C169.836 125.443 169.861 125.705 169.945 125.781C170.636 126.365 171.444 126.5 172.303 126.28C172.977 126.111 173.364 125.629 173.406 124.969C173.457 124.318 173.137 123.886 172.581 123.633Z" fill="white"/>
                                    <path d="M151.891 125.451C151.124 125.384 150.324 125.612 149.516 125.189C149.491 124.758 149.44 124.318 149.44 123.878C149.432 122.635 149.44 121.399 149.423 120.156C149.423 119.885 149.364 119.606 149.288 119.335C149.272 119.259 149.12 119.166 149.036 119.175C148.893 119.183 148.657 119.234 148.623 119.327C148.531 119.581 148.48 119.868 148.48 120.139C148.463 121.831 148.455 123.523 148.463 125.206C148.463 125.528 148.564 125.841 148.632 126.255C149.76 126.255 150.821 126.272 151.874 126.247C152.236 126.238 152.674 126.399 152.918 125.951C153.078 125.697 152.665 125.528 151.891 125.451Z" fill="white"/>
                                    <path d="M129.634 123.768C129.213 123.506 128.699 123.396 128.253 123.176C128.025 123.066 127.84 122.863 127.52 122.618C127.865 122.415 128.076 122.195 128.311 122.169C128.631 122.135 128.977 122.212 129.288 122.313C129.566 122.398 129.819 122.499 130.029 122.22C130.198 121.992 129.996 121.611 129.558 121.492C129.086 121.366 128.573 121.239 128.093 121.281C127.318 121.349 126.846 121.89 126.796 122.592C126.745 123.277 126.998 123.641 127.764 123.98C128.168 124.157 128.606 124.284 128.994 124.504C129.137 124.589 129.28 124.893 129.238 125.054C129.196 125.223 128.951 125.451 128.783 125.468C128.404 125.494 128.017 125.418 127.638 125.358C127.427 125.325 127.225 125.164 127.015 125.155C126.897 125.147 126.745 125.308 126.669 125.435C126.627 125.502 126.653 125.68 126.711 125.739C127.385 126.39 128.749 126.577 129.533 126.137C130.45 125.629 130.526 124.326 129.634 123.768Z" fill="white"/>
                                    <path d="M142.299 123.041C142.299 121.856 142.307 120.672 142.29 119.488C142.29 119.208 142.24 118.929 142.181 118.659C142.164 118.582 142.004 118.456 141.945 118.472C141.81 118.506 141.642 118.591 141.575 118.701C141.49 118.836 141.482 119.022 141.482 119.192C141.474 121.729 141.465 124.259 141.474 126.796C141.474 127.016 141.541 127.236 141.625 127.439C141.65 127.507 141.819 127.558 141.912 127.558C142.004 127.549 142.164 127.473 142.181 127.406C142.248 127.135 142.307 126.856 142.307 126.585C142.324 125.401 142.316 124.216 142.316 123.032C142.307 123.041 142.307 123.041 142.299 123.041Z" fill="white"/>
                                    <path d="M54.0882 121.095C54.0714 120.748 54.0461 120.418 54.0292 120.08C54.0124 119.733 53.9871 119.361 53.524 119.378C53.1535 119.395 53.145 119.716 53.1366 120.004C53.1282 120.283 53.1282 120.57 53.1029 120.85C53.0945 120.951 53.0103 121.044 52.9598 121.146C52.665 121.433 52.025 121.205 51.924 121.932C52.3029 122.093 52.6819 122.262 53.1029 122.44C53.1029 123.117 53.0777 123.734 53.1114 124.343C53.1366 124.952 52.9682 125.578 53.2882 126.145C53.6924 126.28 54.0124 126.187 54.0292 125.748C54.0798 124.732 54.0714 123.726 54.105 122.711C54.1135 122.558 54.2145 122.406 54.2566 122.288C54.5766 122.068 55.1493 122.262 55.124 121.586C54.8124 121.442 54.4756 121.281 54.0882 121.095Z" fill="white"/>
                                    <path d="M177.196 119.724C177.086 119.318 176.783 119.242 176.396 119.589C176.371 120.046 176.345 120.545 176.32 121.002C175.891 121.459 175.217 121.179 175.158 121.949C175.512 122.11 175.891 122.271 176.345 122.474C176.345 123.117 176.328 123.734 176.354 124.343C176.379 124.952 176.219 125.578 176.522 126.162C177.154 126.086 177.246 126.044 177.255 125.739C177.288 124.732 177.305 123.717 177.339 122.711C177.339 122.609 177.415 122.508 177.457 122.398C177.768 122.237 178.088 122.068 178.602 121.797C178.072 121.476 177.718 121.273 177.322 121.027C177.288 120.604 177.305 120.147 177.196 119.724Z" fill="white"/>
                                    <path d="M110.366 121.129C110.307 120.604 110.274 120.114 110.181 119.623C110.164 119.513 109.937 119.378 109.802 119.378C109.676 119.378 109.457 119.521 109.44 119.631C109.364 120.122 109.347 120.621 109.314 121.086C108.893 121.298 108.522 121.476 107.949 121.755C108.514 122.042 108.884 122.22 109.263 122.415C109.524 123.65 109.137 124.952 109.566 126.331C109.861 126.137 110.198 126.035 110.231 125.866C110.324 125.375 110.29 124.868 110.299 124.369C110.316 123.709 110.324 123.041 110.333 122.541C110.653 122.017 111.41 122.338 111.36 121.586C111.057 121.45 110.72 121.289 110.366 121.129Z" fill="white"/>
                                    <path d="M67.4609 119.581C67.444 119.479 67.1998 119.335 67.0735 119.344C66.9388 119.352 66.7198 119.505 66.703 119.623C66.6272 120.114 66.6019 120.621 66.5598 121.146C66.1809 121.298 65.8356 121.442 65.0861 121.738C65.8188 122.068 66.1893 122.237 66.5261 122.398C66.7619 123.683 66.4335 124.935 66.7451 126.111C67.1745 126.314 67.503 126.247 67.5282 125.798C67.5872 124.792 67.5703 123.777 67.5956 122.761C67.604 122.609 67.6798 122.457 67.7303 122.296C67.9156 122.245 68.084 122.212 68.2356 122.144C68.4209 122.059 68.5893 121.949 68.9346 121.746C68.3956 121.467 68.0251 121.273 67.6377 121.078C67.5788 120.579 67.5535 120.071 67.4609 119.581Z" fill="white"/>
                                    <path d="M60.3787 121.569C60.0924 121.425 59.764 121.273 59.4524 121.273C59.1324 121.273 58.8208 121.484 58.5008 121.518C58.1387 121.552 57.7766 121.484 57.2882 121.45C57.204 123.007 57.1872 124.445 57.3135 125.883C57.3303 126.027 57.684 126.145 57.9872 126.348C58.0629 125.917 58.1387 125.663 58.1556 125.392C58.1808 124.775 58.1556 124.157 58.1977 123.54C58.2735 122.465 58.5766 122.186 59.6461 122.152C59.9072 122.144 60.1682 122.118 60.4714 122.102C60.4377 121.865 60.463 121.611 60.3787 121.569Z" fill="white"/>
                                    <path d="M187.528 119.124C187.486 119.039 187.352 118.929 187.284 118.938C187.141 118.963 186.947 119.039 186.897 119.141C186.813 119.335 186.787 119.572 186.787 119.792C186.779 121.645 186.771 123.497 186.779 125.35C186.779 125.612 186.863 125.883 186.905 126.12C187.554 126.297 187.663 125.925 187.672 125.494C187.68 123.531 187.663 121.56 187.655 119.598C187.646 119.445 187.596 119.268 187.528 119.124Z" fill="white"/>
                                    <path d="M92.1431 118.735C92.0504 118.625 91.9073 118.54 91.7725 118.498C91.722 118.481 91.5704 118.599 91.5536 118.676C91.4946 118.887 91.4525 119.107 91.4525 119.327C91.4441 121.789 91.4357 124.25 91.4441 126.72C91.4441 126.991 91.5283 127.253 91.562 127.49C92.1599 127.668 92.1936 127.287 92.1936 126.957C92.202 124.326 92.202 121.696 92.1936 119.065C92.202 118.955 92.202 118.811 92.1431 118.735Z" fill="white"/>
                                    <path d="M63.3766 121.704C63.183 121.56 62.964 121.459 62.6272 121.273C62.4251 122.973 62.4924 124.512 62.5598 126.145C63.124 126.247 63.4103 126.12 63.4103 125.697C63.4356 124.47 63.4272 123.244 63.4272 122.017C63.4272 121.915 63.4356 121.755 63.3766 121.704Z" fill="white"/>
                                    <path d="M155.882 121.543C155.705 121.476 155.52 121.425 155.309 121.357C155.242 121.611 155.166 121.772 155.158 121.924C155.149 123.201 155.141 124.479 155.175 125.756C155.183 126.204 155.495 126.28 156.042 126.035C156.076 124.631 156.135 123.151 155.975 121.67C155.966 121.628 155.924 121.56 155.882 121.543Z" fill="white"/>
                                    <path d="M70.8209 121.712C70.7788 123.1 70.7535 124.487 70.8124 125.866C70.8293 126.255 71.1998 126.34 71.6124 126.027C71.6798 124.538 71.7556 123.007 71.5451 121.315C71.2167 121.484 70.8209 121.594 70.8209 121.712Z" fill="white"/>
                                    <path d="M105.979 121.586C105.92 121.509 105.802 121.408 105.726 121.416C105.583 121.442 105.339 121.535 105.33 121.619C105.263 122.279 105.246 122.948 105.212 123.607C105.238 123.607 105.263 123.607 105.288 123.607C105.288 124.326 105.263 125.054 105.305 125.773C105.314 125.917 105.566 126.044 105.701 126.171C105.827 126.018 106.063 125.874 106.063 125.722C106.088 124.445 106.08 123.167 106.071 121.89C106.08 121.789 106.038 121.67 105.979 121.586Z" fill="white"/>
                                    <path d="M63.3766 119.818C63.4693 119.555 63.2166 119.259 62.9472 119.259C62.6861 119.251 62.4419 119.521 62.5093 119.809C62.5514 119.978 62.7366 120.114 62.9135 120.342C63.124 120.114 63.3177 119.987 63.3766 119.818Z" fill="white"/>
                                    <path d="M155.486 120.156C155.688 120.139 155.882 119.961 156.017 119.885C156.008 119.462 155.714 119.192 155.495 119.302C155.335 119.386 155.158 119.589 155.149 119.75C155.141 119.885 155.377 120.164 155.486 120.156Z" fill="white"/>
                                    <path d="M71.2924 119.268C71.0398 119.166 70.7535 119.437 70.7451 119.724C70.7367 120.012 70.9893 120.249 71.2924 120.173C71.4609 120.131 71.5872 119.936 71.8061 119.75C71.5872 119.53 71.4609 119.327 71.2924 119.268Z" fill="white"/>
                                    <path d="M105.65 119.344C105.499 119.369 105.314 119.615 105.288 119.784C105.255 120.012 105.431 120.232 105.693 120.164C105.861 120.114 105.987 119.936 106.248 119.724C105.97 119.53 105.785 119.318 105.65 119.344Z" fill="white"/>
                                    </svg>
                                </figure>
                          </CardBody>
                      </Card>

                      <Card>
                          <CardBody>
                                <CardTitle tag="h3">LOGIN</CardTitle>

                                <CardSubtitle tag="h4">
                                </CardSubtitle>   
                                <form className="mt-3" onSubmit={doLogin}>
                                    <FormGroup>
                                        <Label for="exampleEmail">Email address</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="exampleEmail"
                                            placeholder="Enter email"
                                            value={auth.email} onChange={handleChange} 
                                            invalid={error.email ? true:false} 
                                        />
                                        { error && 
                                            <FormFeedback>{error.email}</FormFeedback>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="examplePassword">Password</Label>
                                        <Input
                                            type={show_password ? "text":"password"}
                                            name="password"
                                            id="examplePassword"
                                            placeholder="Password"
                                            autoComplete="off"
                                            value={auth.password} onChange={handleChange}
                                            invalid={error.password ? true:false} 
                                        />
                                        { error && 
                                            <FormFeedback>{error.password}</FormFeedback>
                                        }
                                        <div className="visibleEye">
                                            {
                                                show_password ?
                                                    <i className="showEye" onClick={()=>set_show_password(!show_password)}>
                                                        <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18.531 5.59448C16.8435 2.28198 13.4685 0.0319824 9.65601 0.0319824C5.81226 0.0319824 2.43726 2.28198 0.749756 5.59448C0.687256 5.71948 0.656006 5.90698 0.656006 6.06323C0.656006 6.18823 0.687256 6.37573 0.749756 6.50073C2.43726 9.81323 5.81226 12.032 9.65601 12.032C13.4685 12.032 16.8435 9.81323 18.531 6.50073C18.5935 6.37573 18.6248 6.18823 18.6248 6.03198C18.6248 5.90698 18.5935 5.71948 18.531 5.59448ZM9.65601 10.532C7.15601 10.532 5.15601 8.53198 5.15601 6.03198C5.15601 3.56323 7.15601 1.53198 9.65601 1.53198C12.1248 1.53198 14.156 3.56323 14.156 6.03198V6.06323C14.156 8.53198 12.1248 10.532 9.65601 10.532ZM9.65601 3.03198C9.40601 3.06323 9.06226 3.09448 8.84351 3.15698C8.99976 3.37573 9.15601 3.78198 9.15601 4.06323C9.15601 4.87573 8.46851 5.53198 7.65601 5.53198C7.37476 5.53198 6.96851 5.40698 6.74976 5.25073C6.68726 5.46948 6.65601 5.81323 6.65601 6.03198C6.65601 7.68823 7.99976 9.03198 9.65601 9.03198C11.3123 9.03198 12.656 7.68823 12.656 6.03198C12.656 4.37573 11.3123 3.03198 9.65601 3.03198Z" fill="white"></path>
                                                        </svg>
                                                    </i>
                                                :
                                                    <i className="hideEye" onClick={()=>set_show_password(!show_password)}>
                                                        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 12.532C7.625 12.532 5.6875 10.7195 5.53125 8.37573L2.25 5.84448C1.8125 6.40698 1.40625 6.96948 1.09375 7.59448C1.03125 7.71948 1 7.90698 1 8.06323C1 8.18823 1.03125 8.37573 1.09375 8.50073C2.78125 11.8132 6.15625 14.032 10 14.032C10.8125 14.032 11.625 13.907 12.4062 13.7195L10.8125 12.4695C10.5625 12.5007 10.2188 12.532 10 12.532ZM19.7812 14.3757L16.3438 11.6882C17.2188 10.9382 18.3438 9.53198 18.875 8.50073C18.9375 8.37573 18.9688 8.18823 18.9688 8.03198C18.9688 7.90698 18.9375 7.71948 18.875 7.59448C17.1875 4.28198 13.8125 2.03198 10 2.03198C8.625 2.06323 6.5625 2.56323 5.375 3.21948L1.40625 0.156982C1.34375 0.0944824 1.1875 0.0319824 1.09375 0.0319824C0.96875 0.0319824 0.78125 0.125732 0.71875 0.250732L0.09375 1.03198C0.03125 1.09448 0 1.25073 0 1.34448C0 1.46948 0.0625 1.65698 0.1875 1.71948L18.5625 15.9382C18.625 16.0007 18.7812 16.0632 18.875 16.0632C19 16.0632 19.1875 15.9695 19.2812 15.8445L19.875 15.0632C19.9375 15.0007 20 14.8445 20 14.7507C20 14.6257 19.9062 14.4382 19.7812 14.3757ZM14.0625 9.93823L12.8125 8.96948C12.9062 8.71948 12.9688 8.31323 13 8.03198C13 8.03198 13 8.03198 13 8.00073C13 6.37573 11.6562 5.03198 10.0312 5.03198C9.78125 5.03198 9.40625 5.09448 9.1875 5.15698C9.34375 5.37573 9.46875 5.78198 9.5 6.03198C9.46875 6.12573 9.46875 6.28198 9.4375 6.34448L7.125 4.59448C7.8125 4.00073 9.09375 3.56323 10 3.53198C12.4688 3.53198 14.5 5.56323 14.5 8.03198C14.5 8.71948 14.3125 9.34448 14.0625 9.93823Z" fill="white"></path>
                                                        </svg>
                                                    </i>
                                            }
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <div className="forgot">
                                            {/* <a path="#!" href="/">Forgot Password</a> */}
                                            <Link to="/forgot_password">Forgot Password</Link>
                                        </div>
                                    </FormGroup>
                                    {/* disabled={props.show_loder == 1 ? true:false} */}
                                    <Button color="primary" type="submit" >
                                      Login
                                    </Button>
                                </form>
                          </CardBody>
                      </Card>

                      <div className="powered">
                          <h5>Powered By</h5>
                          <p>ThePrimeFit.co</p>
                      </div>
                  </div>
              </Col>

              {/* <Col md="8"> */}
                  {/* <img src={require("assets/img/login-bg.jpg")} /> alt="..." */}
              {/* </Col> */}
          </Row>
      </div>
		</>
	);
}

const mapStateToProps = state => ({
  show_loder: state.commonReducer.show_loder,
  errors: state.commonReducer.errors,
  message: state.commonReducer.message,
})

const mapDispatchToProps = (dispatch) => ({
  userLoginActionTemp: (data, navigate) => dispatch( userLoginAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login)