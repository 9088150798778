import * as t from "../types.js";
import { validationErrors } from "../../helper/ErrorHandler.js";
import APP_CONSTANTS from "../../constants/app.constant.js"
import axios from 'axios'

export const getFaqsListAction = (data, limit, searchBy) => async (dispatch, getState) => {
  let request_status = false;
  let return_data = [];

  dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
  });
  const config = {
      headers: { 
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id
      }
  };
  await axios.get( process.env.REACT_APP_API_URL +'/admin/faqs?limit='+APP_CONSTANTS.DEFAULT_LIMIT_PER_PAGE+(data ? '&page='+data:'')+(searchBy ? '&title='+searchBy:''), config).then((res) => {
      dispatch({
        type: t.SET_FAQS,
        payload: res.data.data,
      });
      dispatch({
        type: t.SET_FAQS_PAGINATION,
        payload: res.data.pagination,
      });
      return_data = res.data.meta;
      request_status = true;
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
  }).catch((err) => {
      dispatch({
          type: t.SET_ERRORS,
          payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
      });
      dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
      });
  });
  
  if (request_status) return return_data;
  else return false;
};

export const faqsViewAction = (data) => async (dispatch, getState) => {
  let request_status = false;
  let return_data = [];

  dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
  });
  const config = {
      headers: { 
        Authorization: `Bearer ${getState().userReducer.token}`,
        userid: getState().userReducer.id
      }
  };
  await axios.get( process.env.REACT_APP_API_URL +'/admin/faqs/'+data, config).then((res) => {
      dispatch({
        type: t.SET_FAQ,
        payload: res.data.data,
      });
      return_data = res.data.data;
      request_status = true;
      dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
      });
  }).catch((err) => {
      dispatch({
          type: t.SET_ERRORS,
          payload: err.response ? err.response.data : (err.response.data ? err.response.data.message:''),
      });
      dispatch({
          type: t.SET_SHOW_LODER,
          payload: 0,
      });
  });
  
  if (request_status) return return_data;
  else return false;
};

export const faqsUpdateAction = (data, id, answerInputList, deletedAnswer, navigate) => async (dispatch, getState) => {
    let object = {};
    data.forEach((value,key) => object[key] = value)
    object = { ...object, answer: answerInputList, deletedAnswer: deletedAnswer };
    let request_status = false;
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 1,
    });
    const config = {
        headers: { 
          Authorization: `Bearer ${getState().userReducer.token}`,
          userid: getState().userReducer.id,
        } 
    };
    await axios.patch( process.env.REACT_APP_API_URL +'/admin/faqs/'+id, object, config).then((res) => {
        dispatch({
            type: t.SET_ERRORS,
            payload: {},
        });
        request_status = true;
        navigate('/admin/app-content')
    }).catch((err) => {
        validationErrors(err)
        dispatch({
            type: t.SET_ERRORS,
            payload: err.response.data.data,
        });
    });
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
    });
    if (request_status) return true;
    else return false;
};

export const faqsCreateAction = (data, answerInputList, navigate) => async (dispatch, getState) => {
  var object = {};
  data.forEach((value, key) => object[key] = value);
  object.answer =answerInputList;
  let request_status = false;
  dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
  });

  const config = {
    headers: { 
      Authorization: `Bearer ${getState().userReducer.token}`,
      userid: getState().userReducer.id
    } 
  };
  await axios.post( process.env.REACT_APP_API_URL +'/admin/faqs', object, config).then((res) => {
   
    dispatch({
      type: t.SET_ERRORS,
      payload: {},
    });
    request_status = true;
    navigate('/admin/app-content')
  }).catch((err) => {
    validationErrors(err)
    dispatch({
      type: t.SET_ERRORS,
      payload: err.response.data.data,
    });
  });
  dispatch({
    type: t.SET_SHOW_LODER,
    payload: 0,
  });
  if (request_status) return true;
  else return false;
};

export const faqsDeleteAction = (data_id, navigate) => async (dispatch, getState) => {
  let request_status = false;
  dispatch({
      type: t.SET_SHOW_LODER,
      payload: 1,
  });
  const config = {
    headers: { 
      Authorization: `Bearer ${getState().userReducer.token}`,
      userid: getState().userReducer.id
    } 
  };
  console.log("data_id: ",data_id);
  await axios.delete( process.env.REACT_APP_API_URL +'/admin/faqs/'+data_id, config).then((res) => {
    dispatch({
        type: t.SET_SHOW_LODER,
        payload: 0,
    });
    request_status = true;
  }).catch((err) => {
    validationErrors(err)
  });
  if (request_status) return true;
  else return false;
};
