import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    Table,
    Badge,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { getPersonalTrainerListAction, PersonalTrainerDeleteAction } from "../../redux/actions/PersonalTrainerAction";
import { connect } from "react-redux"
import Pagination from "../../components/Pagination/Pagination";
import EditIcon from "../../components/Icons/EditIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import ViewIcon from "../../components/Icons/ViewIcon"
// import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";

function PeronsalTrainer (props) {
    const navigate = useNavigate()
    const [currentPageNumber, setcurrentPageNumber] = useState(1);
    const [searchBy, setSearchBy] = useState("");
  
    useEffect(()=>{
      if(currentPageNumber) props.getPersonalTrainerListAction(currentPageNumber).then(response => {});
    },[currentPageNumber]);
    useEffect(()=>{
      if(searchBy === "0") {
        props.getPersonalTrainerListAction(1, "", "").then(response => {});
      }else if(searchBy) {
        props.getPersonalTrainerListAction(1, "", searchBy).then(response => {});
      }
    },[searchBy]);

    const deleteClickHandler = (id) => {
      props.PersonalTrainerDeleteAction(id).then(response => {
        if(response) props.getPersonalTrainerListAction(currentPageNumber);
      });
    }
    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-plain tpf-card">
                <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">
                          Personal Trainers
                          <Badge color="info" pill>{props.personal_trainers_pagination?.totalCount}</Badge>
                        </CardTitle>
                        {/* <p className="category">Sort by:</p> */}
                        <div className="selectDown">
                          <FormGroup>
                            <Label for="sortBy">Sort by:</Label>
                            <Input type="select" name="sortBy" id="SortsortByBy" >
                              <option>Recent</option>
                              <option>...</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </Col>

                      <Col>
                        <div className="filter">
                          <SearchFieldNav setSearchBy={setSearchBy} />

                          <div className="addNew">
                            <Link to="/admin/personal-trainer/create">
                              <Button color="primary">
                                Create new Personal Trainer
                                <i className="fa fa-plus"/>{" "}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Table responsive className="tablesorter tpf-table">
                      <thead className="text-primary">
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone number</th>
                          <th>Expertise</th>
                          <th>Experience</th>
                          <th>Status</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props?.personal_trainers?.map((personal_trainer) => (
                          <tr key={personal_trainer.id}>
                            <td>{personal_trainer.id}</td>
                            <td>{personal_trainer.name}</td>
                            <td>{personal_trainer?.email}</td>
                            <td>{personal_trainer?.phone_number}</td>
                            <td>{personal_trainer.expertise.map((v) => v.title+' | ' )}</td>
                            <td>{personal_trainer.experience}</td>
                            <td>{personal_trainer.status}</td>
                            <td className="text-right">
                              <Button className="btn-icon" color="success" size="sm" onClick={()=>{
                                navigate('/admin/personal-trainer/edit/'+personal_trainer.id, { replace: true, state: personal_trainer })
                              }}>
                                <EditIcon />
                              
                              </Button>{` `}

                              <Button className="btn-icon" color="success" size="sm" onClick={()=>{
                                navigate('/admin/personal-trainer/reviews/'+personal_trainer.id, { replace: true, state: personal_trainer })
                              }}>
                                <ViewIcon />
                              </Button>{` `}
                              <Button className="btn-icon" color="warning" size="sm" onClick={()=>deleteClickHandler(personal_trainer.id)}>
                                <DeleteIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination 
                      current_page={props?.personal_trainers_pagination?.currentPage}
                      limit={props?.personal_trainers_pagination?.limit}
                      previous={props?.personal_trainers_pagination?.previousPage}
                      next={props?.personal_trainers_pagination?.nextPage}
                      total_record={props?.personal_trainers_pagination?.totalCount}
                      total_pages={props?.personal_trainers_pagination?.totalPages}
                      setcurrentPageNumber={setcurrentPageNumber}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

const mapStateToProps = state => ({
  personal_trainers: state.personalTrainerReducer.personal_trainers,
  personal_trainer_pagination: state.personalTrainerReducer.personal_trainers_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  getPersonalTrainerListAction: (data, limit, searchBy) => dispatch( getPersonalTrainerListAction(data, limit, searchBy) ),
  PersonalTrainerDeleteAction: (data, navigate) => dispatch( PersonalTrainerDeleteAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeronsalTrainer)