// userReducer
export const SET_USER = "SET_USER";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const UPDATE_USER = "UPDATE_USER";
export const RESET_USER = "RESET_USER";

// customersReducer
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMERS_PAGINATION = "SET_CUSTOMERS_PAGINATION";
export const RESET_CUSTOMERS = "RESET_CUSTOMERS";

// customersTasks
export const SET_CUSTOMERS_TASK = "SET_CUSTOMERS_TASK";
export const SET_CUSTOMERS_TASKS = "SET_CUSTOMERS_TASKS";
export const SET_CUSTOMERS_TASKS_PAGINATION = "SET_CUSTOMERS_TASKS_PAGINATION";

// customersSubscriptions
export const SET_CUSTOMERS_SUBSCRIPTION = "SET_CUSTOMERS_SUBSCRIPTION";
export const SET_CUSTOMERS_SUBSCRIPTIONS = "SET_CUSTOMERS_SUBSCRIPTIONS";
export const SET_CUSTOMERS_SUBSCRIPTIONS_PAGINATION =
  "SET_CUSTOMERS_SUBSCRIPTIONS_PAGINATION";

// commonReducer
export const SET_SHOW_LODER = "SET_SHOW_LODER";
export const SET_PAGE_NUMBER_COMMON = "SET_PAGE_NUMBER_COMMON";
export const SET_ERRORS = "SET_ERRORS";
export const RESET_ERRORS = "RESET_ERRORS";
export const SET_MESSAGE = "SET_MESSAGE";

// usersReducer
export const SET_VIEW_USER = "SET_VIEW_USER";
export const SET_USERS = "SET_USERS";
export const SET_TOTAL_USERS = "SET_TOTAL_USERS";
export const SET_USERS_PAGINATION = "SET_USERS_PAGINATION";
export const RESET_USERS = "RESET_USERS";

// helpfulTipsReducer
export const SET_HELPFUL_TIP = "SET_HELPFUL_TIP";
export const SET_HELPFUL_TIPS = "SET_HELPFUL_TIPS";
export const SET_HELPFUL_TIPS_PAGINATION = "SET_HELPFUL_TIPS_PAGINATION";
export const RESET_HELPFUL_TIPS = "RESET_HELPFUL_TIPS";

// homeCategoriesReducer
export const SET_HOME_CATEGORIE = "SET_HOME_CATEGORIE";
export const SET_HOME_CATEGORIES = "SET_HOME_CATEGORIES";
export const SET_HOME_CATEGORIES_2 = "SET_HOME_CATEGORIES_2";
export const SET_HOME_CATEGORIES_3 = "SET_HOME_CATEGORIES_3";
export const SET_HOME_CATEGORIES_PAGINATION = "SET_HOME_CATEGORIES_PAGINATION";
export const RESET_HOME_CATEGORIES = "RESET_HOME_CATEGORIES";

// applicationSettingsReducer
export const SET_APPLICATION_SETTING = "SET_APPLICATION_SETTING";
export const SET_APPLICATION_SETTINGS = "SET_APPLICATION_SETTINGS";
export const SET_APPLICATION_SETTINGS_PAGINATION =
  "SET_APPLICATION_SETTINGS_PAGINATION";
export const RESET_APPLICATION_SETTINGS = "RESET_APPLICATION_SETTINGS";

// promotionBannersReducer
export const SET_PROMOTION_BANNER = "SET_PROMOTION_BANNER";
export const SET_PROMOTION_BANNERS = "SET_PROMOTION_BANNERS";
export const SET_PROMOTION_BANNERS_PAGINATION =
  "SET_PROMOTION_BANNERS_PAGINATION";
export const RESET_PROMOTION_BANNERS = "RESET_PROMOTION_BANNERS";

// questionsReducer
export const SET_QUESTION = "SET_QUESTION";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_QUESTIONS_PAGINATION = "SET_QUESTIONS_PAGINATION";
export const RESET_QUESTIONS = "RESET_QUESTIONS";

// entityAndAttributesReducer
export const SET_ENTITIES = "SET_ENTITIES";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const RESET_ENTITY_AND_ATTRIBUTES = "RESET_ENTITY_AND_ATTRIBUTES";

// plansReducer
export const SET_PLAN = "SET_PLAN";
export const SET_PLANS = "SET_PLANS";
export const SET_PLANS_PAGINATION = "SET_PLANS_PAGINATION";
export const RESET_PLANS = "RESET_PLANS";
export const SET_SELECTED_PLAN = "SET_SELECTED_PLAN";
export const SET_PLAN_TYPES = "SET_PLAN_TYPES";

// mealsReducer
export const SET_MEAL = "SET_MEAL";
export const SET_MEALS = "SET_MEALS";
export const SET_MEALS_PAGINATION = "SET_MEALS_PAGINATION";
export const RESET_MEALS = "RESET_MEALS";
export const SET_PAGE_NUMBER_MEAL = "SET_PAGE_NUMBER_MEAL";

// foodTypesReducer
export const SET_FOOD_TYPE = "SET_FOOD_TYPE";
export const SET_FOOD_TYPES = "SET_FOOD_TYPES";
export const SET_FOOD_TYPES_PAGINATION = "SET_FOOD_TYPES_PAGINATION";
export const RESET_FOOD_TYPES = "RESET_FOOD_TYPES";

// calorieCalculationsReducer
export const SET_CALORIE_BY_TITLE = "SET_CALORIE_BY_TITLE";
export const SET_CALORIE_CALCULATION = "SET_CALORIE_CALCULATION";
export const SET_CALORIE_CALCULATIONS = "SET_CALORIE_CALCULATIONS";
export const SET_CALORIE_CALCULATIONS_PAGINATION =
  "SET_CALORIE_CALCULATIONS_PAGINATION";
export const RESET_CALORIE_CALCULATIONS = "RESET_CALORIE_CALCULATIONS";

// workoutsReducer
export const SET_WORKOUT = "SET_WORKOUT";
export const SET_WORKOUTS = "SET_WORKOUTS";
export const SET_WORKOUTS_PAGINATION = "SET_WORKOUTS_PAGINATION";
export const RESET_WORKOUTS = "RESET_WORKOUTS";
export const SET_PAGE_NUMBER_WORKOUT = "SET_PAGE_NUMBER_WORKOUT";

// medicalInstructionsReducer
export const SET_MEDICAL_INSTRUCTION = "SET_MEDICAL_INSTRUCTION";
export const SET_MEDICAL_INSTRUCTIONS = "SET_MEDICAL_INSTRUCTIONS";
export const SET_MEDICAL_INSTRUCTIONS_PAGINATION =
  "SET_MEDICAL_INSTRUCTIONS_PAGINATION";
export const RESET_MEDICAL_INSTRUCTIONS = "RESET_MEDICAL_INSTRUCTIONS";
export const SET_PAGE_NUMBER_MEDICAL_INSTRUCTIONS =
  "SET_PAGE_NUMBER_MEDICAL_INSTRUCTIONS";

// contentOfTheDaysReducer
export const SET_CONTENT_OF_THE_DAY = "SET_CONTENT_OF_THE_DAY";
export const SET_CONTENT_OF_THE_DAYS = "SET_CONTENT_OF_THE_DAYS";
export const SET_CONTENT_OF_THE_DAYS_PAGINATION =
  "SET_CONTENT_OF_THE_DAYS_PAGINATION";
export const RESET_CONTENT_OF_THE_DAYS = "RESET_CONTENT_OF_THE_DAYS";

// dailyTasksReducer
export const SET_DAILY_TASK = "SET_DAILY_TASK";
export const SET_DAILY_TASKS = "SET_DAILY_TASKS";
export const SET_DAILY_TASKS_PAGINATION = "SET_DAILY_TASKS_PAGINATION";
export const RESET_DAILY_TASKS = "RESET_DAILY_TASKS";
export const SET_PAGE_NUMBER_DAILY_TASK = "SET_PAGE_NUMBER_DAILY_TASK";

// MilestonesReducer
export const SET_SELECTED_MILESTONE = "SET_SELECTED_MILESTONE";
export const SET_MILESTONE = "SET_MILESTONE";
export const SET_MILESTONES = "SET_MILESTONES";
export const SET_MILESTONES_PAGINATION = "SET_MILESTONES_PAGINATION";
export const RESET_MILESTONES = "RESET_MILESTONES";
export const SET_PAGE_NUMBER_MILESTONE = "SET_PAGE_NUMBER_MILESTONE";

// RamazanContentOfTheDays
export const SET_RAMAZAN_CONTENT_OF_THE_DAY = "SET_RAMAZAN_CONTENT_OF_THE_DAY";
export const SET_RAMAZAN_CONTENT_OF_THE_DAYS =
  "SET_RAMAZAN_CONTENT_OF_THE_DAYS";
export const SET_RAMAZAN_CONTENT_OF_THE_DAYS_PAGINATION =
  "SET_RAMAZAN_CONTENT_OF_THE_DAYS_PAGINATION";
export const RESET_RAMAZAN_CONTENT_OF_THE_DAYS = "RESET_CONTENT_OF_THE_DAYS";
export const SET_PAGE_NUMBER_RAMAZAN_CONTENT_OF_THE_DAY =
  "SET_PAGE_NUMBER_RAMAZAN_CONTENT_OF_THE_DAY";

// ramazanTasksReducer
export const SET_RAMAZAN_TASK = "SET_RAMAZAN_TASK";
export const SET_RAMAZAN_TASKS = "SET_RAMAZAN_TASKS";
export const SET_RAMAZAN_TASKS_PAGINATION = "SET_RAMAZAN_TASKS_PAGINATION";
export const RESET_RAMAZAN_TASKS = "RESET_RAMAZAN_TASKS";
export const SET_PAGE_NUMBER_RAMAZAN_TASK = "SET_PAGE_NUMBER_RAMAZAN_TASK";

// RamazanMilestonesReducer
export const SET_SELECTED_RAMAZAN_MILESTONE = "SET_SELECTED_RAMAZAN_MILESTONE";
export const SET_RAMAZAN_MILESTONE = "SET_RAMAZAN_MILESTONE";
export const SET_RAMAZAN_MILESTONES = "SET_RAMAZAN_MILESTONES";
export const SET_RAMAZAN_MILESTONES_PAGINATION =
  "SET_RAMAZAN_MILESTONES_PAGINATION";
export const RESET_RAMAZAN_MILESTONES = "RESET_RAMAZAN_MILESTONES";
export const SET_PAGE_NUMBER_RAMAZAN_MILESTONE =
  "SET_PAGE_NUMBER_RAMAZAN_MILESTONE";

// SectionReducer
export const SET_SELECTED_SECTION = "SET_SELECTED_SECTION";
export const SET_SECTION = "SET_SECTION";
export const SET_SECTIONS = "SET_SECTIONS";
export const SET_ALL_SECTIONS = "SET_ALL_SECTIONS";
export const SET_SECTIONS_PAGINATION = "SET_SECTIONS_PAGINATION";
export const RESET_SECTIONS = "RESET_SECTIONS";

export const SET_DASHBOARD_CARDS = "SET_DASHBOARD_CARDS";

export const SET_CHAT_GROUP = "SET_CHAT_GROUP";
export const SET_CHAT_GROUPS_LIST = "SET_CHAT_GROUPS_LIST";
export const SET_CHAT_GROUPS_PAGINATION = "SET_CHAT_GROUPS_PAGINATION";
export const RESET_CHAT_GROUPS = "RESET_CHAT_GROUPS";
export const ACTIVE_CHAT_MODULE = "ACTIVE_CHAT_MODULE";
//PersonalTrainerReducer
export const SET_SELECTED_PERSONAL_TRAINER = "SET_SELECTED_PERSONAL_TRAINER";
export const SET_PERSONAL_TRAINER = "SET_PERSONAL_TRAINER";
export const SET_PERSONAL_TRAINERS = "SET_PERSONAL_TRAINERS";
export const SET_ALL_PERSONAL_TRAINERS = "SET_ALL_PERSONAL_TRAINERS";
export const SET_PERSONAL_TRAINERS_PAGINATION =
  "SET_PERSONAL_TRAINERS_PAGINATION";
export const RESET_PERSONAL_TRAINERS = "RESET_PERSONAL_TRAINERS";

//PackagePriceReducer
export const SET_PACKAGE_PRICE = "SET_PACKAGE_PRICE";
export const SET_PACKAGE_PRICES = "SET_PACKAGE_PRICES";
export const SET_ALL_PACKAGE_PRICES = "SET_ALL_PACKAGE_PRICES";
export const SET_PACKAGE_PRICES_PAGINATION = "SET_PACKAGE_PRICES_PAGINATION";
export const RESET_PACKAGE_PRICES = "RESET_PACKAGE_PRICES";

export const SET_TRAINING_EXPERTISE = "SET_TRAINING_EXPERTISE";
export const SET_TRAINING_EXPERTISES = "SET_TRAINING_EXPERTISES";
export const SET_ALL_TRAINING_EXPERTISES = "SET_ALL_TRAINING_EXPERTISES";
export const SET_TRAINING_EXPERTISES_PAGINATION =
  "SET_TRAINING_EXPERTISES_PAGINATION";
export const RESET_TRAINING_EXPERTISES = "RESET_TRAINING_EXPERTISES";

export const SET_BOOKED_SESSION = "SET_BOOKED_SESSION";
export const SET_BOOKED_SESSIONS = "SET_BOOKED_SESSIONS";
export const SET_BOOKED_SESSIONS_PAGINATION = "SET_BOOKED_SESSIONS_PAGINATION";
export const RESET_BOOKED_SESSIONS = "RESET_BOOKED_SESSIONS";

export const SET_ALL_PERSONAL_TRAINER_REVIEWS =
  "SET_ALL_PERSONAL_TRAINER_REVIEWS";
export const SET_PERSONAL_TRAINER_REVIEWS_PAGINATION =
  "SET_PERSONAL_TRAINER_REVIEWS_PAGINATION";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATIONS";
export const SET_NOTIFICATIONS_PAGINATION = "SET_NOTIFICATIONS_PAGINATION";
export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

// questionsReducer
export const SET_FAQ = "SET_FAQ";
export const SET_FAQS = "SET_FAQS";
export const SET_FAQS_PAGINATION = "SET_FAQS_PAGINATION";
export const RESET_FAQS = "RESET_FAQS";
