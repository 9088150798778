import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    Table,
    Badge,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { getTrainingExpertiseAction, trainingExpertiseDeleteAction } from "../../redux/actions/trainingExpertiseAction";
import { connect } from "react-redux"
import Pagination from "../../components/Pagination/Pagination";
import EditIcon from "../../components/Icons/EditIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
// import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";

function TrainingExpertise (props) {
    const navigate = useNavigate();
    const [currentPageNumber, setcurrentPageNumber] = useState(1);
    const [searchBy, setSearchBy] = useState("");

    useEffect(()=>{
      if(currentPageNumber) props.getTrainingExpertiseAction(currentPageNumber,"","").then(response => {});
    },[currentPageNumber]);

    useEffect(()=>{
      if(searchBy === "0") {
        props.getTrainingExpertiseAction(1, "", "").then(response => {});
      }else if(searchBy) {
        props.getTrainingExpertiseAction(1, "", searchBy).then(response => {});
      }
    },[searchBy]);

    const deleteClickHandler = (id) => {
      props.trainingExpertiseDeleteAction(id).then(response => {
        if(response) props.getTrainingExpertiseAction(currentPageNumber,"","");
      });
    }
    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-plain tpf-card">
                <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">
                          Training Expertise
                          <Badge color="info" pill>{props.training_expertises_pagination?.totalCount}</Badge>
                        </CardTitle>
                        {/* <p className="category">Sort by:</p> */}
                        <div className="selectDown">
                          <FormGroup>
                            <Label for="sortBy">Sort by:</Label>
                            <Input type="select" name="sortBy" id="SortsortByBy" >
                              <option>Recent</option>
                              <option>...</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </Col>

                      <Col>
                        <div className="filter">
                          <SearchFieldNav setSearchBy={setSearchBy} />

                          <div className="addNew">
                            <Link to={`/admin/training-expertise/create/`}>
                              <Button color="primary">
                                Create new Training Expertise
                                <i className="fa fa-plus"/>{" "}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Table responsive className="tablesorter tpf-table">
                      <thead className="text-primary">
                        <tr>
                          <th>ID</th>
                          <th>Title</th>
                          <th>Image</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props?.training_expertises?.map((data) => (
                          <tr key={data.id}>
                            <td>{data.id}</td>
                            <td>{data.title}</td>
                            <td>  <img alt="not fount" width={"50px"} src={data?.image} /> </td>
                            <td className="text-right">
                              <Button className="btn-icon" color="success" size="sm" onClick={()=> {
                                navigate('/admin/training-expertise/edit/'+data.id, { replace: true, state: data })
                              }}>
                                <EditIcon />
                              </Button>{` `}
                              <Button className="btn-icon" color="warning" size="sm" onClick={()=>deleteClickHandler(data.id)}>
                                <DeleteIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination 
                      current_page={props?.training_expertises_pagination?.currentPage}
                      limit={props?.training_expertises_pagination?.limit}
                      previous={props?.training_expertises_pagination?.previousPage}
                      next={props?.training_expertises_pagination?.nextPage}
                      total_record={props?.training_expertises_pagination?.totalCount}
                      total_pages={props?.training_expertises_pagination?.totalPages}
                      setcurrentPageNumber={setcurrentPageNumber}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

const mapStateToProps = state => ({
  training_expertises: state.trainingExpertiseReducer.training_expertises,
  training_expertises_pagination: state.trainingExpertiseReducer.training_expertises_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  getTrainingExpertiseAction: (data, limit, searchBy) => dispatch( getTrainingExpertiseAction(data, limit, searchBy) ),
  trainingExpertiseDeleteAction: (data, navigate) => dispatch( trainingExpertiseDeleteAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingExpertise)