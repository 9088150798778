import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { 
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";

const MultiSelectDropdown = (props) => {
  const { heading, options, type, error, value } = props;
  const [selected, setSelected] = useState([]);
  const [DropDownOptions, setDropDownOptions] = useState([]);
  const [lastValue, setLastValue] = useState([]);

  useEffect(() => {
    if(value) {
      if(Array.isArray(value)){
        let data = [];
        value.map(v => {
          data.push({label: v.title,value:v.id })
        });
        setSelected(data);
      }
    }
  }, [value])

  useEffect(() => {
    if(options) {
      let optionsTemp = [];
      if(type) {
        optionsTemp = options.filter(value => {return value.type === type})
      } 
      else {
        optionsTemp = options;
      } 
      let data = [];
      optionsTemp.map(v => {
        data.push({label: v.title,value:v.id })
      });
      console.log(data,'SATA');
      setDropDownOptions(data);
    }
  }, [options])
  useEffect(() => {
    if(selected){
      let data = [];
      selected.map(v => {
        data.push(v.value)
      });
      setLastValue(data)
    }
  }, [selected])
  return (
    <FormGroup className="col-lg-6">
      <Label for="category">{heading}</Label>
      <MultiSelect className="multi" options={DropDownOptions} value={selected} onChange={setSelected} labelledBy="Select" closeMenuOnSelect={false} isMulti   
      />
      { error && <FormFeedback>{error.category}</FormFeedback> }
      <input type="hidden" name={props.name ? props.name : "category"} id="category" value={lastValue} /> 
    </FormGroup>
  );
};

export default MultiSelectDropdown;
