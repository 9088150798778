import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    FormGroup,
    Label,
    Row, 
    Col,
    Button,
    Badge,
} from "reactstrap";
import { getFaqsListAction, faqsDeleteAction } from "../../redux/actions/faqsAction";
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import EditIcon from "../../components/Icons/EditIcon";
import Pagination from "../../components/Pagination/Pagination";
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";

function Faqs (props) {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const initialPage = parseInt(urlParams.get("page"), 10) ? parseInt(urlParams.get("page"), 10) : 1;
  const initialSearch = urlParams.get("searchBy") ?? ""
  const [currentPageNumber, setcurrentPageNumber] = useState(initialPage);
  const [searchBy, setSearchBy] = useState(initialSearch);

    useEffect(()=>{
      if(currentPageNumber) props.getFaqsListAction(currentPageNumber,"",searchBy).then(response => {});
    },[currentPageNumber,searchBy]);

    const deleteClickHandler = (id) => {
      props.faqsDeleteAction(id).then(response => {
        if(response) props.getFaqsListAction(currentPageNumber);
      });
    }
    return (
      <>
        <Col md="12">
          <Card className="card-plain tpf-card">
            <CardHeader>
              <Row>
                <Col md="4">
                  <CardTitle tag="h4">
                    FAQS
                    <Badge color="info" pill>{props.faqs_pagination.totalCount}</Badge>
                  </CardTitle>
                  <div className="selectDown">
                    <FormGroup>
                      <Label for="sortBy">Recent</Label>
                    </FormGroup>
                  </div>
                </Col>
                <Col>
                  <div className="filter">
                    <SearchFieldNav setSearchBy={setSearchBy} />

                    <div className="addNew">
                      <Link to="/admin/faqs/create">
                        <Button color="primary">
                          Create new FAQS
                          <i className="fa fa-plus"/>{" "}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody >
              <Row>
                  {props.faqs && props.faqs.map((faqs) => (
                    <Col lg="6" key={faqs.id}>
                        <div className="tpf-horizontal-card p-3 with-bg my-2">
                            <Row>
                                <Col lg="10" className="d-flex align-items-center">
                                    <div className="title">
                                        <h5 className="card-title font-weight-bold">{faqs.title}</h5>
                                        <p>Total answers are {faqs.answers.length}</p>
                                    </div>
                                </Col>

                                <Col lg="2" className="d-flex justify-content-end">
                                    <Button className="btn-icon" color="primary" size="sm" onClick={()=>{
                                        navigate('/admin/faqs/edit/'+faqs.id, { replace: true, state: faqs })
                                      }}>
                                        <EditIcon />
                                    </Button>
                                    <Button className="btn-icon" color="warning" size="sm" onClick={()=>deleteClickHandler(faqs.id)}>
                                        <DeleteIcon />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                  ))}
              </Row>
              <Pagination 
                current_page={props.faqs_pagination.currentPage}
                limit={props.faqs_pagination.limit}
                previous={props.faqs_pagination.previousPage}
                next={props.faqs_pagination.nextPage}
                total_record={props.faqs_pagination.totalCount}
                total_pages={props.faqs_pagination.totalPages}
                setcurrentPageNumber={setcurrentPageNumber}
                search={searchBy}
              />
            </CardBody>
          </Card>
        </Col>
      </>
    )
}

const mapStateToProps = state => ({
  faqs: state.faqsReducer.faqs,
  faqs_pagination: state.faqsReducer.faqs_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  getFaqsListAction: (data, limit, searchBy) => dispatch( getFaqsListAction(data, limit, searchBy) ),
  faqsDeleteAction: (data, navigate) => dispatch( faqsDeleteAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faqs)