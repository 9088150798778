import React , { useEffect,useRef } from "react";
import notificationSound from '../../assets/sound/notification_tune.mp3';
import * as t from "../../redux/types.js";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import anime3 from '../../assets/img/anime3.png';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav
} from "reactstrap";
import {
  BackgroundColorContext,
} from "../../contexts/BackgroundColorContext";
import { userLogoutAction } from "../../redux/actions/userAction";
import { connect } from "react-redux"
import APP_CONSTANTS from "../../constants/app.constant";
import pusherService from "../../service/pusherService.js";

var ps;
function Sidebar(props) {
    const navigate = useNavigate()
    const notificationSoundRef = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const sidebarRef = React.useRef(null);
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };
    useEffect(() => {
        const channelNames = APP_CONSTANTS.CHAT_CHANNEL;
        pusherService.subscribeToChannels(channelNames, APP_CONSTANTS.NEW_CHAT_EVENT, (channelName, data) => {
            dispatch({
                type: t.ACTIVE_CHAT_MODULE,
                payload: true,
            });
            playNewMessageSound();
        });

        pusherService.subscribeToChannels(channelNames, APP_CONSTANTS.NEW_MESSAGE, (channelName, data) => {
            dispatch({
                type: t.ACTIVE_CHAT_MODULE,
                payload: true,
            });
            playNewMessageSound();
        });
        return () => {
            pusherService.unsubscribeToChannels(APP_CONSTANTS.CHAT_CHANNEL)
        };
    },[props.active_chat]);

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebarRef.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });
    const playNewMessageSound = () => {
        
        if (notificationSoundRef.current) {
            const audioElement = notificationSoundRef.current;
            audioElement.play().catch(error => {
                console.error('Failed to play notification sound:', error);
                document.body.addEventListener("mousemove", function () {
                    audioElement.play()
                })  
            });
        }
        return true
    };
    const logoutFunc = ()=>{
        props.userLogoutAction().then(response => {
            navigate('/');
        });
    }
    const linkOnClick = () => {
        document.documentElement.classList.remove("nav-open");
    };
    const { routes, rtlActive, logo } = props;
    let logoImg = null;
    let logoText = null;
    if (logo !== undefined) {
        if (logo.outterLink !== undefined) {
            logoImg = (
                <a href={logo.outterLink} className="simple-text logo-mini" target="_blank" onClick={props.toggleSidebar}>
                    <div className="logo-img">
                        <img src={logo.imgSrc} alt="react-logo" />
                    </div>
                </a>
            );
            logoText = (
                <a href={logo.outterLink} className="simple-text logo-normal" target="_blank" onClick={props.toggleSidebar} >
                    {logo.text}
                </a>
            );
        } 
        else {
            logoImg = (
                <Link to={logo.innerLink} className="simple-text logo-mini" onClick={props.toggleSidebar} >
                    <div className="logo-img">
                        <img src={logo.imgSrc} alt="react-logo" />
                    </div>
                </Link>
            );
            logoText = (
                <Link to={logo.innerLink} className="simple-text logo-normal" onClick={props.toggleSidebar} >
                    {logo.text}
                </Link>
            );
        }
    }
  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar">
            <div>
                <audio ref={notificationSoundRef} src={notificationSound}/>
            </div>
          <div className="sidebar-wrapper" ref={sidebarRef}>
            {logoImg !== null || logoText !== null ? (
              <div className="logo">
                {logoImg}
                {/* {logoText} */}
              </div>
            ) : null}

            <Card className="card-plain mt-3 p-0 mb-0 sidebar-card ">
              <CardBody className="py-0">
                <div className="tpf-horizontal-card">
                  <Row className="mt-3">
                      <Col xs="4">
                        <figure className="figImage rounded-pill">
                          <img src={anime3} alt="..."/>
                        </figure>
                      </Col>

                      <Col xs="8" className="d-flex align-items-center pl-0">
                        <div className="title">
                          <h5 className="card-title font-weight-bold">{props.fullName}</h5>
                          <p>View Account Info</p>
                        </div>
                      </Col>
                    </Row>
                </div>
              </CardBody>
            </Card>
            
            <Nav>
              <li>
                <NavLink
                  to={"/admin/dashboard"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Dashboard</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/app-content"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>AppContent</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/calorieCalculations"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Calorie Calculations</p>
                </NavLink>
              </li>

              {/* 
              comment for now 
              <li>
                <NavLink
                  to={"/admin/chat-support"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>ChatSupport</p>
                </NavLink>
              </li> */}

              <li>
                <NavLink
                  to={"/admin/contentOfTheDays"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Content Of The Days</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/customers"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Customers</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/dailyTasks"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Daily Task</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/foodTypes"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Food Types</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/meals"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Meal Plans</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/medicalInstructions"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Medical Instructions</p>
                </NavLink>
              </li>
              
              <li>
                <NavLink
                  to={"/admin/ramazanContentOfTheDays"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Ramazan COTD</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/ramazanTasks"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Ramazan Tasks</p>
                </NavLink>
              </li>

              {/* 
              comment for now 
              <li>
                <NavLink
                  to={"/admin/push-notifications"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Push Notifications</p>
                </NavLink>
              </li> */}

              <li>
                <NavLink
                  to={"/admin/plans"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Subscription Plans</p>
                </NavLink>
              </li>
              
              <li>
                <NavLink
                  to={"/admin/users"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Users</p>
                </NavLink>
              </li>
              
              <li>
                <NavLink
                  to={"/admin/workouts"}
                  className="nav-link"
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Workouts</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/admin/chat-support"}
                  className='nav-link'
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Chat Support  {props.active_chat && <span className="attention-dot"></span>}</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/admin/notifications"}
                  className='nav-link'
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Notifications</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/admin/sections"}
                  className='nav-link'
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Sections</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/personal-trainer"}
                  className='nav-link'
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Personal Trainer</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/training-expertise"}
                  className='nav-link'
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Training Expertise</p>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={"/admin/booked-sessions"}
                  className='nav-link'
                  activeclassname="active"
                  onClick={props.toggleSidebar}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Training Sessions</p>
                </NavLink>
              </li>
              
              <li>
                <a href="javascript:;"
                  className="nav-link"
                  // activeclassname="active"
                  onClick={() => logoutFunc()}
                >
                  <i>
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.78253 6.64555C4.94464 6.64555 5.90753 5.71586 5.90753 4.52055C5.90753 3.35844 4.94464 2.39555 3.78253 2.39555C2.58722 2.39555 1.65753 3.35844 1.65753 4.52055C1.65753 5.71586 2.58722 6.64555 3.78253 6.64555ZM18.6575 6.64555C19.8196 6.64555 20.7825 5.71586 20.7825 4.52055C20.7825 3.35844 19.8196 2.39555 18.6575 2.39555C17.4622 2.39555 16.5325 3.35844 16.5325 4.52055C16.5325 5.71586 17.4622 6.64555 18.6575 6.64555ZM19.72 7.70805H17.595C16.9974 7.70805 16.4661 7.97367 16.0677 8.33891C17.429 9.06937 18.3587 10.3975 18.5911 11.958H20.7825C21.347 11.958 21.845 11.4932 21.845 10.8955V9.83305C21.845 8.67094 20.8821 7.70805 19.72 7.70805ZM11.22 7.70805C13.2454 7.70805 14.9388 6.04789 14.9388 3.9893C14.9388 1.96391 13.2454 0.270546 11.22 0.270546C9.16144 0.270546 7.50128 1.96391 7.50128 3.9893C7.50128 6.04789 9.16144 7.70805 11.22 7.70805ZM13.7435 8.77055H13.4778C12.7806 9.10258 12.0169 9.3018 11.22 9.3018C10.39 9.3018 9.62628 9.10258 8.92902 8.77055H8.66339C6.53839 8.77055 4.84503 10.4971 4.84503 12.6221V13.5518C4.84503 14.4483 5.5423 15.1455 6.43878 15.1455H16.0013C16.8646 15.1455 17.595 14.4483 17.595 13.5518V12.6221C17.595 10.4971 15.8685 8.77055 13.7435 8.77055ZM6.33917 8.33891C5.94073 7.97367 5.40948 7.70805 4.84503 7.70805H2.72003C1.52472 7.70805 0.595032 8.67094 0.595032 9.83305V10.8955C0.595032 11.4932 1.05988 11.958 1.65753 11.958H3.81573C4.04816 10.3975 4.97784 9.06937 6.33917 8.33891Z" fill="white"/>
                  </svg>
                  </i>
                  <p>Logout</p>
                </a>
              </li>

            
              
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

const mapStateToProps = state => ({
  fullName: state.userReducer.fullName,
  email: state.userReducer.email,
  profile_image: state.userReducer.profile_image,
  active_chat: state.chatGroupReducer.active_chat
})

const mapDispatchToProps = (dispatch) => ({
  userLogoutAction: (data, navigate) => dispatch( userLogoutAction(data, navigate) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)