import * as t from "../types";

const defaultState = {
  selected_ramazan_milestone: '',
  ramazan_milestone: [],
  ramazan_milestones: [],
  ramazan_milestones_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,},
};

const ramazanMilestonesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_SELECTED_RAMAZAN_MILESTONE:
      return {
        ...state,
        selected_ramazan_milestone: action.payload,
      };
    case t.SET_RAMAZAN_MILESTONE:
      return {
        ...state,
        ramazan_milestone: action.payload,
      };
    case t.SET_RAMAZAN_MILESTONES:
      return {
        ...state,
        ramazan_milestones: action.payload,
      };
    case t.SET_RAMAZAN_MILESTONES_PAGINATION:
      return {
        ...state,
        ramazan_milestones_pagination: action.payload,
      };
    case t.RESET_RAMAZAN_MILESTONES:
      return {
        ...state,
        selected_ramazan_milestone: '',
        ramazan_milestone: [],
        ramazan_milestones: [],
        ramazan_milestones_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default ramazanMilestonesReducer;
