import * as t from "./types";

const defaultState = {
  booked_session: [],
  booked_sessions: [],
  booked_sessions_pagination: {
    currentPage:1,
    limit:10,
    previousPage:null,
    nextPage:2,
    totalCount:0,
    totalPages:1,},
};

const bookedSessionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_BOOKED_SESSION:
      return {
        ...state,
        booked_session: action.payload,
      };
    case t.SET_BOOKED_SESSIONS:
      return {
        ...state,
        booked_sessions: action.payload,
      };
    case t.SET_BOOKED_SESSIONS_PAGINATION:
      return {
        ...state,
        booked_sessions_pagination: action.payload,
      };
    case t.RESET_BOOKED_SESSIONS:
      return {
        ...state,
        booked_session: [],
        booked_sessions: [],
        booked_sessions_list: [],
        booked_sessions_pagination: {
          currentPage:1,
          limit:10,
          previousPage:null,
          nextPage:2,
          totalCount:0,
          totalPages:1,
        },
      };
    default:
      return state;
  }
}

export default bookedSessionReducer;
